import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
// import Loading from "../../../Components/Loading/Loading";
import Loading from "../../../Components/Loading/PEC/Loading";
import { fetchQuery, graphql } from "relay-runtime";
import moment from "moment";
import environment from "../../../Environment";
import "./index.scss";
import { DeviceChecking } from "../../../Services/Common/device_checking";
import HandleSSOtoOtherPlatform from "../../../Services/Common/HandleSSOtoOtherPlatform";
import { config } from "../../../Config/config";
import NavBar from "../../../Components/NavBar/Dashboard/PEC/NavBar";
// import dummy_img1 from "../../../assets/img/pec/all-images/cover-elect-1a.png";
import default_dummy_img from "../../../assets/img/pec/all-images/cover-default.png";
import downloadIcon from "../../../assets/img/pec/all-images/icon-download-apps@2x.png";
import Footer from "../../../Components/Footer/PEC/Footer";
import likedIcon from "../../../assets/img/pec/all-images/icon-heart-grey-small.png";
import likeIcon from "../../../assets/img/pec/all-images/icon-heart-outline-grey-small.png";
import { dashboard_book_level_favorites } from "../../../Actions";
import UpdateUserPreferencesMutation from "../../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
import DefaultSDLPage from "./DefaultSDLPage";

import UnitPage from "./UnitPage";
import UnitPageStudent from "./UnitPageStudent";
import DownloadReportExcel from "./DownloadReportExcel";
import {processImageUrl} from "../../../utils/ImageProcessUtils";
import Modal from "react-modal";
import useInAppBrowser from "../../../hooks/useInAppBrowser";
import useGATracking from "../../../hooks/useGATracking";
const B2BBody = (props) => {
  const {
    t,
    B2C,
    //ClassId,
    studyGroupList,
    //setClassId,
    //setSelectGroupClass,
    //setSelectClass,
    //selectGroupClass,
    //selectClass,
    bookData,
    updateBookData,
    allBookData,
    selectedBookSeriesName,
    selectedBookSeriesId,
    selectedBookName,
    selectedBookId,
    studentClassId,
    bookSeriesDataSDL,
    sdlResourceButton,
    setSdlResourceButton,
    sdlResourceLoader, 
    setSdlResourceLoader
  } = props;
  const dispatch = useDispatch();
  const bookseries_dropdownRef = useRef(null);
  const classseries_dropdownRef = useRef(null);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const lang = useSelector((state) => state.SystemReducer.lang);
  // const role = localStorage.getItem("role");
  const userData = useSelector((state) => state.UserReducer.user);
  const role = userData?.UserType;
  const user_preferences = useSelector(
    (state) => state.UserReducer.user_preferences
  );
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  const selectOptionsSDL = useSelector(
    (state) => state.EntryOfResourceReducer.selectOptions
  );

  const user_rcenter_view_preferences = useSelector(
    (state) => state.UserReducer.user_rcenter_view_preferences
  );
  const UserType = userData?.UserType;

  const [showReportLoading, setShowReportLoading] = useState(false);

  const [bredCrumRes, setbredCrumRes] = useState([]);
  const [showSelectedBook, setShowSelectedBook] = useState(false);
  const [showClassDropdown, setShowClassDropdown] = useState(false);
  const [showSDLPage, setShowSDLPage] = useState(false);
  const [resourceIframeURL, setresourceIframeURL] = useState("");

  
  const [favouriteValue, setFavouriteValue] = useState(0);
  const [sdlActiveClass, setSdlActiveClass] = useState("all");
  const [clickable, setClickable] = useState(false);
  const [classNameLoading, setClassNameLoading] = useState(true);
  const [downloadAction, setdownloadAction] = useState(true);
  const [doDownload, setdoDownload] = useState("");
  const [retainChapter, setretainChapter] = useState("");
  const [retainChapterName, setretainChapterName] = useState("");
 // alert(selectOptions.selectedToggle)
  const [toggleProgress, setToggleProgress] = useState(
    (UserType == "S") ? true : (selectOptions.selectedToggle) ? selectOptions.selectedToggle : false 
  );
  const [refreshBtn, setrefreshBtn] = useState(0);
 //console.log("test commit")
  const [favouriteLoading, setFavouriteLoading] = useState(false);
  const [favouriteLoadingId, setFavouriteLoadingId] = useState('');
   
  const [unitLoading, setUnitLoading] = useState(false);
  const [levelLoadingx, setlevelLoadingx] = useState(false);
  const [favStudyGroupList, setfavStudyGroupList] = useState([]);
  const [favBookLevel, setfavBookLevel] = useState([]);
  const [showEBook, setshowEBook] = useState(false);
  const [EBookObj, setEBookObj] = useState({});

  const [showRes, setshowRes] = useState(false);
  const [ResObj, setResObj] = useState({});
  const [bookSection, setBookSection] = useState("resource");
  const [bookClassId, setbookClassId] = useState("");
  const [gatagDownloadName, setgatagDownloadName] = useState("");
  

  const [openTab, setopenTab] = useState((selectOptions.showIframeOrSDL)? selectOptions.showIframeOrSDL : "sdl");
  const [tabLoader, settabLoader] = useState(selectOptions.showIframeOrSDL=="sdl"? false : true);

  const askForInstall = useSelector((state) => state.OpenInstallModalReducer.askForInstall);
  const askForInstallToolData = useSelector((state) => state.OpenInstallModalReducer.askForInstallToolData);
  const {systemBrowserOpenLink} = useInAppBrowser();
  const { sendGaEvent } = useGATracking();
  const IS_MODAL_OPEN_KEY = "sdl_modal_open";

  const askForInstallPopUp = (tool) => {
    dispatch({
        type: "SET_ASK_FOR_INSTALL",
        askForInstall: true,
    });
    dispatch({
        type: "SET_ASK_FOR_INSTALL_TOOL_DATA",
        askForInstallToolData: tool,
    });
  };

  const closeAskForInstallPopUp = () => {
    dispatch({
        type: "SET_ASK_FOR_INSTALL",
        askForInstall: false,
    });
    dispatch({
        type: "SET_ASK_FOR_INSTALL_TOOL_DATA",
        askForInstallToolData: null,
    });
  }

  const askForInstallOnClick = () => {
    let device = DeviceChecking();
    // const { askForInstallToolData } = this.state;
    // const lang = localStorage.getItem("i18nextLng");
    if (device == "ANDROID_APP") {
      console.log(
          "[App] App is not installed, redirct to play store url path:" +
          config.playStore +
          askForInstallToolData.toolData.AndroidAppId +
          (lang == "en-US" ? config.playStoreEng : config.playStoreChi)
      );
      systemBrowserOpenLink(
          config.playStore +
          askForInstallToolData.toolData.AndroidAppId +
          (lang == "en-US" ? config.playStoreEng : config.playStoreChi)
      );
    }
    if (device == "IOS_APP") {
      console.log(
          "[App] App is not installed, redirct to App store url:",
          encodeURI(
              config.AppStore +
              askForInstallToolData.toolData.IOSAppId +
              (lang == "en-US" ? config.AppStoreEng : config.AppStoreChi)
          )
      );
      closeAskForInstallPopUp();
      systemBrowserOpenLink(
          encodeURI(
              config.AppStore +
              askForInstallToolData.toolData.IOSAppId +
              (lang == "en-US" ? config.AppStoreEng : config.AppStoreChi)
          )
      );
    }
  };

  const [selectClass, setSelectClass] = useState([
    {
      StudyGroupName: "",
      StudyGroupId: "",
    },
  ]);
  const [selectGroupClass, setSelectGroupClass] = useState("");
  const [ClassId, setClassId] = useState(role == "T" ? "" : studentClassId);
  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        bookseries_dropdownRef.current !== null &&
        !bookseries_dropdownRef.current.contains(e.target)
        // &&        !document.querySelector(".book__series").contains(e.target)
      ) {
        setShowSelectedBook(false);
      }
    };
    if (showSelectedBook) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showSelectedBook]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        classseries_dropdownRef.current !== null &&
        !classseries_dropdownRef.current.contains(e.target) &&
        !document.querySelector(".Component1").contains(e.target)
      ) {
        setShowClassDropdown(false);
      }
    };
    if (showClassDropdown) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showClassDropdown]);

  useEffect(() => {
    

    if (studyGroupList.length > 0) {
      if (UserType == "T") {
        var favbooklevelvar = [];
        var preferencesObjB = dashboard_book_level_favorites(
          userData.Preferences
        );

        console.log("testtts", preferencesArrB);
        if (preferencesObjB.length > 0) {
          var preferencesArrB = JSON.parse(preferencesObjB[0].PreferenceValue);
          let filterList = preferencesArrB.filter(
            (o) =>
              o.BSId == bookData.BookId &&
              o.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          if (filterList.length > 0) {
            var obj = {
              BSId: bookData.BookId,
              StructureId: filterList[0].StructureId,
              SchoolYear: filterList[0].SchoolYear,
            };
            favbooklevelvar.push(obj);
          }
          setfavBookLevel(favbooklevelvar);
        }
        var bookLevelId = "";
        var favStudyGroupListArr = [];
        var selectedLevelForBook = favbooklevelvar.filter(
          (o) =>
            o.BSId == selectedBookId &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        //alert(selectOptionsSDL.selectedClassLevelId)
        if (selectOptionsSDL.selectedClassLevelId) {
          // alert(selectOptionsSDL.selectedClassLevelId);
          setClassId(selectOptionsSDL.selectedClassLevelId);
          setClassNameLoading(false);
          setSelectGroupClass(selectOptionsSDL.selectedClassLevelName);
          setSelectClass([
            {
              StudyGroupName: selectOptionsSDL.selectedClassLevelName,
              StudyGroupId: selectOptionsSDL.selectedClassLevelId,
            },
          ]);

          if (selectedLevelForBook.length > 0) {
            favStudyGroupListArr = studyGroupList.filter((o) =>
              selectedLevelForBook[0].StructureId.includes(o.StudyGroupId)
            );
          } 
        } else {
          //Test
          if (selectedLevelForBook.length > 0) {
            favStudyGroupListArr = studyGroupList.filter((o) =>
              selectedLevelForBook[0].StructureId.includes(o.StudyGroupId)
            );
            if (favStudyGroupListArr.length > 0) {
              setClassId(favStudyGroupListArr[0].StudyGroupId);
              setClassNameLoading(false);
              setSelectGroupClass(
                favStudyGroupListArr[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                  .StudyGroupName
              );
              setSelectClass([
                {
                  StudyGroupName:
                    favStudyGroupListArr[0].StudyGroupName[
                      [0].Lang == lang ? 0 : 1
                    ].StudyGroupName,
                  StudyGroupId: favStudyGroupListArr[0].StudyGroupId,
                },
              ]);
            } else {
              setClassId(studyGroupList[0].StudyGroupId);
              setClassNameLoading(false);
              setSelectGroupClass(
                studyGroupList[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                  .StudyGroupName
              );
              setSelectClass([
                {
                  StudyGroupName:
                    studyGroupList[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                      .StudyGroupName,
                  StudyGroupId: studyGroupList[0].StudyGroupId,
                },
              ]);
            }
          } else {
            setClassId(studyGroupList[0].StudyGroupId);
            setClassNameLoading(false);
            setSelectGroupClass(
              studyGroupList[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                .StudyGroupName
            );
            setSelectClass([
              {
                StudyGroupName:
                  studyGroupList[0].StudyGroupName[[0].Lang == lang ? 0 : 1]
                    .StudyGroupName,
                StudyGroupId: studyGroupList[0].StudyGroupId,
              },
            ]);
          }
        }

        setfavStudyGroupList(favStudyGroupListArr);
      }
    }
  }, [studyGroupList]);

  const toggleClickable = (val) => {
    if (val == 1) {
      setClickable(true);
    } else {
      setClickable(false);
    }
  };

  useEffect(() => {
    if (bookData.UsefulTools) {
      var ebookLinkObj = bookData.UsefulTools.filter(
        (o) =>
          o.ToolsType == "ebook" &&
          (o.ApplicableRole == UserType || o.ApplicableRole == "ALL")
      );
      if (ebookLinkObj.length > 0) {
        setshowEBook(true);
        setEBookObj(ebookLinkObj[0]);
      }
      var resourceLinkObj = bookData.UsefulTools.filter(
        (o) =>
          o.ToolsType == "web" &&
          (o.ApplicableRole == UserType || o.ApplicableRole == "ALL")
      );
      //console
      if (resourceLinkObj.length > 0) {
        setshowRes(true);
        setResObj(resourceLinkObj[0]);
      }
      if(UserType == "T"){
        handleTRCIframeLoad(resourceLinkObj[0])
      }
      
      if(openTab !="sdl"){
        setTimeout(() => {
          settabLoader(false)
        }, 5000);
        //settabLoader(false)
      }
      console.log("suresh",openTab);
      console.log("sdl page", resourceLinkObj, showRes);
      console.log("role", role);
      console.log("UserType", UserType);
    }
  }, [bookData]);

  // const updateDispatch = () => {
  //   dispatch({
  //     type: "SET_SELECTED_TYPE",
  //     selectOptions: {
  //       selectedToggle:toggleProgress
  //      },
  //   });
  // }
  const retainChapterFunc = (chapterId, chapterName, forGatag="no") => {

    if(forGatag=="yes"){
      setgatagDownloadName(chapterId)
    }else{
      setretainChapter(chapterId)
    setretainChapterName(chapterName)
    dispatch({
      type: "SET_SELECTED_TYPE",
      selectOptions: {
       selectecResourceTab : chapterId,
        selectedResourceName: chapterName,
        selectedToggle:toggleProgress
       },
    });
    }
    
  }
  const redirectDashboard = () => {
    props.history.push("/m");
    // props.history.push({
    // pathname: sdlpath,
    // state: {bookSeriesNameArg: activeMenuName,
    // bookSeriesIdArg: bookseriesId,
    // bookIdArg: bookId,
    // bookNameArg: bookName,}
    // });
    dispatch({
      type: "SET_SELECTED_BOOK",
      selectOptions: {
        selectedBookId: selectedBookId,
        selectedBookName: selectedBookName,
        selectedBookSeriesName: selectedBookSeriesName,
        selectedBookSeriesId: selectedBookSeriesId,
      },
    });
  };

  // get the class data starts here (studygrouplist)

  const onClickTool = (bookseriesId, action, bookId = "", bookName = "") => {
     
    
    let filterList = bookSeriesDataSDL.filter(
      (o) => o.BookSeriesId == bookseriesId
    );
    //var usefulToolsObj = filterList[0].UsefulTools;
    let pre_tools = JSON.parse(JSON.stringify(filterList[0].UsefulTools));
    pre_tools = pre_tools.sort((a, b) => a.DisplaySequence - b.DisplaySequence);

    var items = [];
    pre_tools.map((obj, i) => {
      if (
        ((obj.WebURL == "" || obj.WebURL == null) && // no web data
          !window.cordova) || //not show in web verison
        (obj.WebSupported == "N" &&
          obj.TabletSupported == "N" &&
          obj.MobileSupported == "N" &&
          obj.PCSupported == "Y" &&
          window.cordova) || //only show in PC
        (obj.TabletSupported == "N" &&
          obj.MobileSupported == "N" &&
          (obj.WebSupported == "Y" || obj.PCSupported == "Y") &&
          window.cordova)
      )
        return;
      if (obj.ApplicableRole == "T") {
        if (userData.UserType == "T") {
          items.push({
            order: 1,
            type: obj.ToolsType,
            icon: obj.ToolsIconFile.FilePath,
            showOpenIcon: true,
            label: obj.UsefulToolsLangs,
            toolData: obj,
          });
        }
      } else {
        items.push({
          order: 1,
          type: obj.ToolsType,
          icon: obj.ToolsIconFile.FilePath,
          showOpenIcon: true,
          label: obj.UsefulToolsLangs,
          toolData: obj,
        });
      }
    });
    items = items.sort((a, b) => a.order - b.order);

    
    var toolList = items.filter((o) => o.type == "web");
    

    var tool = toolList[0];
    const device = DeviceChecking();
    let webLink, link;

    console.log("today", tool)

    //alert(tool?.type)
    switch (tool?.type) {
      case " web":
        if (device == "IOS_APP") {
          if (
            tool.toolData.IOSAppId != "" &&
            tool.toolData.IOSAppId != null &&
            tool.toolData.IOSAppName != "" &&
            tool.toolData.IOSAppName != null &&
            tool.toolData.IOSAppURL != "" &&
            tool.toolData.IOSAppURL != null
          ) {
            var getIdName = tool.toolData.IOSAppURL.slice(
              0,
              tool.toolData.IOSAppURL.indexOf("://") + 3
            );

            appAvailability.check(
              getIdName, // URI Scheme
              () => {
                // Success callback
                link =
                  lang == "en-US"
                    ? tool.toolData.IOSAppURL
                    : tool.toolData.IOSAppURLChi;
                return HandleSSOtoOtherPlatform("IOS", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                // Error callback

                askForInstallPopUp(tool);
              }
            );
          } else {
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "ANDROID_APP") {
          if (
            tool.toolData.AndroidAppId != "" &&
            tool.toolData.AndroidAppId != null &&
            tool.toolData.AndroidAppURL != "" &&
            tool.toolData.AndroidAppURL != null
          ) {
            //have android data

            appAvailability.check(
              tool.toolData.AndroidAppId, // Package Name
              (info) => {
                // Success callback
                // Info parameter is available only for android
                link =
                  lang == "en-US"
                    ? tool.toolData.AndroidAppURL
                    : tool.toolData.AndroidAppURLChi;

                return HandleSSOtoOtherPlatform("ANDROID", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                askForInstallPopUp(tool);
              }
            );
          } else {
            //no android data
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool?.toolData.WebURL : tool?.toolData.WebURLChi;

          return HandleSSOtoOtherPlatform("WEB", {
            platform: tool?.type,
            url: webLink,
          });
        }
        break;
      
      default:
        if (device == "IOS_APP") {
          if (
            tool.toolData.IOSAppId != "" &&
            tool.toolData.IOSAppId != null &&
            tool.toolData.IOSAppName != "" &&
            tool.toolData.IOSAppName != null &&
            tool.toolData.IOSAppURL != "" &&
            tool.toolData.IOSAppURL != null
          ) {
            var getIdName = tool.toolData.IOSAppURL.slice(
              0,
              tool.toolData.IOSAppURL.indexOf("://") + 3
            );

            appAvailability.check(
              getIdName, // URI Scheme
              () => {
                // Success callback
                link =
                  lang == "en-US"
                    ? tool.toolData.IOSAppURL
                    : tool.toolData.IOSAppURLChi;
                return HandleSSOtoOtherPlatform("IOS", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                // Error callback

                askForInstallPopUp(tool);
              }
            );
          } else {
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "ANDROID_APP") {
          if (
            tool.toolData.AndroidAppId != "" &&
            tool.toolData.AndroidAppId != null &&
            tool.toolData.AndroidAppURL != "" &&
            tool.toolData.AndroidAppURL != null
          ) {
            //have android data

            appAvailability.check(
              tool.toolData.AndroidAppId, // Package Name
              (info) => {
                // Success callback
                // Info parameter is available only for android
                link =
                  lang == "en-US"
                    ? tool.toolData.AndroidAppURL
                    : tool.toolData.AndroidAppURLChi;

                return HandleSSOtoOtherPlatform("ANDROID", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                askForInstallPopUp(tool);
              }
            );
          } else {
            //no android data
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool?.toolData.WebURL : tool?.toolData.WebURLChi;

          return HandleSSOtoOtherPlatform("WEB", {
            platform: tool?.type,
            url: webLink,
          });
        }
        break;
    }
  };

  const handleTRCIframeLoad = async (obj) =>{
    var items = [];
    if (obj.ApplicableRole == "T") {
      if (userData.UserType == "T") {
        items.push({
          order: 1,
          type: obj.ToolsType,
          icon: obj.ToolsIconFile.FilePath,
          showOpenIcon: true,
          label: obj.UsefulToolsLangs,
          toolData: obj,
        });
      }
    } else {
      items.push({
        order: 1,
        type: obj.ToolsType,
        icon: obj.ToolsIconFile.FilePath,
        showOpenIcon: true,
        label: obj.UsefulToolsLangs,
        toolData: obj,
      });
    }
    let webLink, link;
    var tool = items[0];
    webLink =
    lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
    HandleSSOtoOtherPlatform("IFRAME", {
            platform: tool.type,
            url: webLink,
          }).then((data, error) => {
            console.log("test", data)
              setresourceIframeURL(data)
              //setSdlResourceLoader(false)
          });
  };
  const onClickEbook = async (obj) => {
    var items = [];
    if (
      ((obj.WebURL == "" || obj.WebURL == null) && // no web data
        !window.cordova) || //not show in web verison
      (obj.WebSupported == "N" &&
        obj.TabletSupported == "N" &&
        obj.MobileSupported == "N" &&
        obj.PCSupported == "Y" &&
        window.cordova) || //only show in PC
      (obj.TabletSupported == "N" &&
        obj.MobileSupported == "N" &&
        (obj.WebSupported == "Y" || obj.PCSupported == "Y") &&
        window.cordova)
    )
      return;
    if (obj.ApplicableRole == "T") {
      if (userData.UserType == "T") {
        items.push({
          order: 1,
          type: obj.ToolsType,
          icon: obj.ToolsIconFile.FilePath,
          showOpenIcon: true,
          label: obj.UsefulToolsLangs,
          toolData: obj,
        });
      }
    } else {
      items.push({
        order: 1,
        type: obj.ToolsType,
        icon: obj.ToolsIconFile.FilePath,
        showOpenIcon: true,
        label: obj.UsefulToolsLangs,
        toolData: obj,
      });
    }

    const device = DeviceChecking();
    let webLink, link;
    var tool = items[0];
    console.log(tool);
    switch (tool.type) {
      case "EbookLinkage":
      case "ebook":
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
          return HandleSSOtoOtherPlatform("WEB", {
            platform: "ebook",
            url: webLink,
          });
        }
        if (device == "ANDROID_APP") {
          if (tool.toolData.AndroidAppId !== null && tool.toolData.AndroidAppURL !== null) {
            link = tool.toolData.AndroidAppURL;
            HandleSSOtoOtherPlatform("ANDROID", {
              platform: "ebook",
              url: link,
            });
            setTimeout( ()=> {
              if (document.visibilityState === 'visible'){
                askForInstallPopUp(tool);
              }
            }, 1000);
          } else {
            // openAppPath(link);
            webLink = tool.toolData.WebURL;
            HandleSSOtoOtherPlatform("WEB", {
              platform: "ebook",
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "IOS_APP") {
          link =
              lang == "en-US"
                  ? tool.toolData.IOSAppURL
                  : tool.toolData.IOSAppURLChi;

          if (
              tool.toolData.IOSAppId !== null &&
              tool.toolData.IOSAppName !== null &&
              tool.toolData.IOSAppURL !== null
          ) {
            // var getIdName = tool.toolData.IOSAppURL.slice(
            //     0,
            //     tool.toolData.IOSAppURL.indexOf("://") + 3
            // );
            console.log("HERE IS READY TO REDIRECT");
            HandleSSOtoOtherPlatform("IOS", {
              platform: "ebook",
              url: link,
            });
            console.log("HERE IS TIMEOUT");
            setTimeout( ()=> {
              if (document.visibilityState === 'visible'){
                console.log("true");
                askForInstallPopUp(tool);
              }
            }, 1000);
            console.log("HERE IS TIMEOUT END");
          } else {
            webLink = tool.toolData.WebURL;
            HandleSSOtoOtherPlatform("WEB", {
              platform: "ebook",
              url: webLink,
              isDefaultBrowser: true,
            });
            // openAppPath(link);
          }
        }
        break;
      default:
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;

          return HandleSSOtoOtherPlatform("WEB", {
            platform: tool.type,
            url: webLink,
          });
        } else {
          webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
          return HandleSSOtoOtherPlatform("IOS", {
            platform: "ebook",
            url: webLink,
          });
        }
        break;
    }
  };
  // function to open the link in new tab ends here
  // get the class data ends here

  const toggleCheckResource = (
    e,
    bookSeriesIdArg,
    structureIdArg,
    val,
    bkName
  ) => {
    //alert(bookSeriesIdArg);
    e.stopPropagation();
    setFavouriteLoading(true)
    toggleClickable(1); //disable other action till the action completed
    console.log("newvals", bookSeriesIdArg, structureIdArg, val, bkName);
    // setresFavIds((prev) => ({
    //   ...prev,
    //   [structureIdArg]: val === "yes" ? true : false,
    // }));
    // const val = true ? 'yes': 'no'
    var respreferencesArr = [];
    //setmyResFav
    var latestStructureId = [];
    var preferencesObj = dashboard_book_level_favorites(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on adding to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        console.log("filterList", filterList);

        if (filterList.length > 0) {
          let updateStructureList = [];
          //'27210', '27209', '27211', '27212'
          filterList[0].StructureId.forEach(function (id, index) {
            updateStructureList.push(id);
          });
          updateStructureList.push(structureIdArg);
          console.log("added", updateStructureList);
          var updateObj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(updateStructureList)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };

          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          const indx = localrespreferencesArr.findIndex(
            (v) =>
              v.BSId == bookSeriesIdArg &&
              v.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          console.log(indx);
          console.log("aamer1", localrespreferencesArr);
          localrespreferencesArr.splice(indx, indx >= 0 ? 1 : 0);
          console.log("aamer1 splice", localrespreferencesArr);
          localrespreferencesArr.push(updateObj);
          console.log("aamer2", localrespreferencesArr);
          //console.log("suresh",localrespreferencesArr);
          respreferencesArr = [...localrespreferencesArr];
          latestStructureId = [...new Set(updateStructureList)];
        } else {
          console.log("suresh-exists -filterList.length<=0");
          let localrespreferencesArr = JSON.parse(
            preferencesObj[0].PreferenceValue
          );
          let structureVal = [];
          structureVal.push(structureIdArg);
          var obj = {
            BSId: bookSeriesIdArg,
            StructureId: [...new Set(structureVal)],
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
          localrespreferencesArr.push(obj);
          respreferencesArr = [...localrespreferencesArr];
          //respreferencesArr.push(localrespreferencesArr);
          latestStructureId = [...new Set(structureVal)];
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);

        // on removing to favourites menu lists , ends here
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        console.log("bremoved filter", filterList);
        //Removing From Structure
        let updateStructureList = [];
        filterList[0].StructureId.forEach(function (id, index) {
          updateStructureList.push(id);
        }); //\"27212\",\"27213\",\"27214\",\"27209\
        console.log("bremoved", updateStructureList);
        const indx = updateStructureList.findIndex((v) => v == structureIdArg);
        updateStructureList.splice(indx, indx >= 0 ? 1 : 0);
        console.log("removed", updateStructureList);
        //Removing From BookSeries
        let localrespreferencesArr = JSON.parse(
          preferencesObj[0].PreferenceValue
        );
        const bindx = localrespreferencesArr.findIndex(
          (v) =>
            v.BSId == bookSeriesIdArg &&
            v.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        console.log("aamer3 before", localrespreferencesArr);
        localrespreferencesArr.splice(bindx, bindx >= 0 ? 1 : 0);
        var updateObj = {
          BSId: bookSeriesIdArg,
          StructureId: [...new Set(updateStructureList)],
          SchoolYear: selectedSchoolYear.SchoolYearId,
        };
        console.log("aamer3 after", localrespreferencesArr);
        localrespreferencesArr.push(updateObj);
        respreferencesArr = [...localrespreferencesArr];
        /// respreferencesArr.push(localrespreferencesArr);
        latestStructureId = [...new Set(updateStructureList)];
      }
    } else {
      let structureVal = [];
      structureVal.push(structureIdArg);
      var obj = {
        BSId: bookSeriesIdArg,
        StructureId: structureVal,
        SchoolYear: selectedSchoolYear.SchoolYearId,
      };
      respreferencesArr.push(obj);
      latestStructureId = structureVal;
    }
    console.log("latestStructureId", latestStructureId, respreferencesArr);

    var data = {
      PreferenceName: "DASHBOARD_BOOK_LEVEL_NEW_FAVORITES",
      // PreferenceValue: JSON.stringify([]),
      // SchoolYear: selectedSchoolYear.SchoolYearId,
      PreferenceValue: JSON.stringify(respreferencesArr),
    };
    //setFavorites(respreferencesArr);
    //setmyResFav([...new Set(latestStructureId)]);
    console.log("data", data);
    const callback = (result) => {
      console.log("result", result);
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });
      localStorage.setItem("userData", JSON.stringify(userData));
      console.log("ddd", respreferencesArr);
      setTimeout(() => {
        setFavouriteLoading(false)
        toggleClickable(0); //enable once the action completed
      }, 2000);
    };
    var favStudyGroupListArr = [];
    var selectedLevelForBook = respreferencesArr.filter(
      (o) =>
        o.BSId == selectedBookId &&
        o.SchoolYear == selectedSchoolYear.SchoolYearId
    );
    if (selectedLevelForBook.length > 0) {
      favStudyGroupListArr = studyGroupList.filter((o) =>
        selectedLevelForBook[0].StructureId.includes(o.StudyGroupId)
      );
    }
    setfavStudyGroupList(favStudyGroupListArr);
    setfavBookLevel(respreferencesArr);
    UpdateUserPreferencesMutation(data, callback);
  };

  const test = (scoreVal, scoreType) => {
    if (!isNaN(scoreVal)) {
      if (scoreType == "score") {
        if (scoreVal <= 20) {
          return "student-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "student-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "student-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "student-progressbar-high";
        } else if (scoreVal > 80) {
          return "student-progressbar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "average") {
        if (scoreVal <= 20) {
          return "progress-linebar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "progress-linebar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "progress-linebar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "progress-linebar-high";
        } else if (scoreVal > 80) {
          return "progress-linebar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "largescroll") {
        if (scoreVal <= 20) {
          return "large-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "large-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "large-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "large-progressbar-high";
        } else if (scoreVal > 80) {
          return "large-progressbar-highest";
        } else {
          return "";
        }
      }
    }
    return "";
  };
  const getScoreAndAverageClass = (scoreVal, scoreType) => {
    if (scoreVal === "") {
      return "";
    } else {
      if (scoreType == "score") {
        if (scoreVal <= 20) {
          return "student-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "student-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "student-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "student-progressbar-high";
        } else if (scoreVal > 80) {
          return "student-progressbar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "average") {
        if (scoreVal <= 20) {
          return "progress-linebar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "progress-linebar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "progress-linebar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "progress-linebar-high";
        } else if (scoreVal > 80) {
          return "progress-linebar-highest";
        } else {
          return "";
        }
      } else if (scoreType == "defaultAverageTop") {
          return "default-progress-linebar";        
      } else if (scoreType == "defaultAverageBottom") {
        return "default-progress-linebar1";        
      } else if (scoreType == "largescroll") {
        if (scoreVal <= 20) {
          return "large-progressbar-lowest";
        } else if (scoreVal > 20 && scoreVal <= 40) {
          return "large-progressbar-low";
        } else if (scoreVal > 40 && scoreVal <= 60) {
          return "large-progressbar-medium";
        } else if (scoreVal > 60 && scoreVal <= 80) {
          return "large-progressbar-high";
        } else if (scoreVal > 80) {
          return "large-progressbar-highest";
        } else {
          return "";
        }
      }
    }
  };

  const appendClass = () => {
    console.log("qwerty", { selectGroupClass, selectClass });
    if (selectGroupClass && selectGroupClass.length <= 2) {
      return "Class";
    } else if (selectClass && selectClass.StudyGroupName) {
      return "Class";
    }
  };

  return (
    <>
      {window.cordova && (
          <Modal
              isOpen={askForInstall}
              onAfterOpen={() => {}}
              onRequestClose={() => {
                closeAskForInstallPopUp();
              }}
              style={{
                content: {
                  maxWidth: "450px",
                },
              }}
              contentLabel="As For Install"
              ariaHideApp={false}
              //shouldCloseOnOverlayClick={false}
          >
            <div className="modal-header modal-header-colored">
            </div>
            <div className="modal-body text-center">
              <div style={{ maxWidth: "330px", margin: "0 auto" }}>
                If you're using a tablet computer, install the <b>Pearson e-bookshelf</b> app now.
                <br/>
                <br/>
                <i>(Use of e-Books not recommended on mobile phones due to resolution restrictions.)</i>
              </div>
            </div>
            <div className="modal-footer text-center">
              <button
                  className="btn btn-primary btn-lg"
                  onClick={() => askForInstallOnClick()}
              >
                {t("Install")}
              </button>
            </div>
            <button
                type="button"
                className="react-modal-close"
                title="Close"
                onClick={() => dispatch({ type: "SET_ASK_FOR_INSTALL", askForInstall: false })}
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1"
                  viewBox="0 0 24 24"
              >
                <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
              </svg>
            </button>
          </Modal>
      )}
      <div style={openTab!="sdl"?{paddingRight:"0px"}:null} className={`main__section  ${clickable ? "not-clickable" : ""}`}>
        <div className="main__section-header-group">
          <div className="main__section-header">
            <div className="main__section-header-top">
              <div className="main__section-top">
                <i
                  className="main__section-top-logo"
                  onClick={() => {
                    redirectDashboard();
                  }}
                ></i>
              </div>
              <img
                src={
                  bookData.BookImage
                    ? processImageUrl(bookData.BookImage.FilePath)
                    : default_dummy_img
                }
                alt="book image"
                className="main__section-book-img"
              />
              {/* <button className="btns buttons sdl-ebook-btn" onClick={() => {}}>
              {t("E-Book")}
              <i className="icon mdi mdi-open-in-new"></i>
            </button> */}
              {showEBook && (
                <button
                  className="btns buttons sdl-ebook-btn"
                  onClick={() => onClickEbook(EBookObj)}
                >
                  {t("E-Book")}
                  <i className="icon mdi mdi-open-in-new"></i>
                </button>
              )}
            </div>
            {/* <button className="btns buttons sdl-sdl-btn">
              <span className="mdi-open-in-new-sdl"></span>
              {t("SDL")}
            </button> */}
            <div class="btns-group">
              {openTab == 'sdl' && <div class="btn-corner btn-corner-top"></div>}
              <button class={`btns buttons ${openTab == 'sdl' ? 'sdl-sdl-btn' : 'main-sdl-sdl-btn'}`} 
              onClick={()=> setopenTab('sdl')}>
                <span class={`${openTab == 'sdl' ? 'mdi-open-in-new-sdl' : 'mdi-open-in-new-sdl-resource'}`}></span>
                {t("SDL")}
              </button>
              {openTab == 'sdl' &&  <div class="btn-corner btn-corner-bottom"></div>}
              {UserType == "T" && showRes && (
                <>
                {openTab == 'resource' && 
                <div class="btn-corner btn-corner-top"></div>}
                <div className="main__section-teacher-resource">
                  <button
                    className={`btns buttons sdl-sdl-btn ${openTab == 'resource'? 'sdl-sdl-resource-btn-color' : 'sdl-sdl-btn-color'}`}
                    onClick={() => {
                       settabLoader(true)
                       setopenTab('resource')
                      setTimeout(() => {
                        settabLoader(false)
                      }, 3000);
                      console.log("Surehs", ResObj)
                      // handleTRCIframeLoad(ResObj);
                     
                    }}
                  >
                    <span className={`${openTab == 'resource'? 'mdi-open-in-new-teacher-resource-active' : 'mdi-open-in-new-teacher-resource'}`}></span>
                    {t("Teacher's Resources")}
                  </button>
                </div>
                {openTab == 'resource' &&  <div class="btn-corner btn-corner-bottom"></div>}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="main__section-right-side">
          <div className="main__section-right-side-top">
            <div className="main__section-breadcrumb">
              {/* <div className="main__section-breadcrumb-text">
                <span
                  className="main__section-breadcrumb-left"
                  onClick={() => {
                    redirectDashboard();
                  }}
                >
                  Dashboard <span className="sdl-greater-symbol-small"></span>
                </span>
                <span className="main__section-breadcrumb-right">
                  {selectedBookSeriesName} Book {selectedBookName}
                  <span className="sdl-greater-symbol-bigger"></span> SDL
                </span>
              </div> */}

              <div class="main__section-breadcrumb-text">
                <div
                  class="main__section-breadcrumb-left"
                  onClick={() => {
                    redirectDashboard();
                  }}
                >
                  {/* Dashboard */}
                  {t("Home")}
                </div>
                <div class="sdl-greater-symbol-small"></div>
                <div class="main__section-breadcrumb-flex">
                  {selectedBookSeriesName}{" "}
                  <div class="sdl-greater-symbol-small"></div>{" "}
                  {selectedBookName}
                </div>
                <div class="sdl-greater-symbol-bigger"></div>
                <div class="main__section-breadcrumb-right">{openTab == 'sdl' ? t("SDL") : t("Teacher Resource")}</div>
              </div>
            </div>
            <div className="main__section-navigation">
              <NavBar {...props} showFlag={false} hanbookFlag={true} />
            </div>
          </div>
          <div className="main__section-right-side-bottom">
            <div className="main__section-heading">
              <div className="main__section-heading-book">
                <h1
                  className="main__section-heading-bookseries"
                  onClick={() => {
                    setShowSelectedBook(!showSelectedBook);
                  }}
                  ref={bookseries_dropdownRef}
                >
                  {selectedBookName}
                  <span className="main__section-heading-carret"></span>
                </h1>
                {showSelectedBook ? (
                  <div
                    className={`book__series ${
                      selectedBookName.length > 59 || window.cordova? "" : "book__series-block"
                    }`}
                  >
                    {allBookData.map((el) => {
                      return (
                        <div className="book__series-section">
                          <h2book__series
                            className={`book__series-section-name ${
                              selectedBookId == el.BookId
                                ? "book__series-section-active"
                                : ""
                            }`}
                            onClick={() => {
                              setdoDownload("");
                              setShowSelectedBook(false);
                              // setlevelLoading(true);
                              updateBookData(el.BookId);
                              dispatch({
                                type: "SET_SELECTED_TYPE",
                                selectOptions: {

                                  selectedResourceName:"",
                                  selectecResourceTab:"",
                                  selectedToggle:toggleProgress,
                                  showIframeOrSDL:openTab

                                },
                              });
                              // if(sdlResourceButton == 'resource'){
                              //   setSdlResourceLoader(true)
                              //   setTimeout(() => {
                              //     setSdlResourceLoader(false)
                              //   }, 3000);
                              // }
                              // setlevelLoading(false);
                            }}
                          >
                            {
                              el.BookName[el.BookName[0].Lang == lang ? 0 : 1]
                                .BookName
                            }
                          </h2book__series>
                          <span className="book__series-section-border"></span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {UserType == "S" ? (
                <>{showSDLPage && <DefaultSDLPage t={t} />}</>
              ) : (
                <>
                  <div className="main-year-drop-down-sdl">
                    {classNameLoading ? (
                      <div className="class-dropdown-loader-alignment">
                        <Loading type={`normal`} />
                      </div>
                    ) : (
                      openTab == 'sdl' ? <span
                        class="year-drop-down-sdl"
                        onClick={() => {
                          setShowClassDropdown(!showClassDropdown);
                          // setShowClassData([favBookData.BookId]);
                        }}
                        ref={classseries_dropdownRef}
                        title={`${t(appendClass())} ${
                          selectGroupClass
                            ? selectGroupClass
                            : selectClass.StudyGroupName
                        }`}
                      >
                        {/* Class 1A */}
                        {/* {`${t("Class")} ${
  selectGroupClass
    ? selectGroupClass
    : selectClass.StudyGroupName
}`} */}
                        {`${t(appendClass())} ${
                          selectGroupClass
                            ? selectGroupClass
                            : selectClass.StudyGroupName
                        }`}
                      </span> : ''
                    )}
                  </div>

                  {openTab == 'sdl' && showClassDropdown ? (
                    <div className="Component1 main-component1">
                      <div className="main-container">
                        <div
                          onClick={() => {
                            setFavouriteValue(1);
                            setSdlActiveClass("all");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-all ${
                            sdlActiveClass == "all"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("All")}
                        </div>
                        <div
                          onClick={() => {
                            setFavouriteValue(0);
                            setSdlActiveClass("favourite");
                          }}
                          className={`static-tab-dropdown-one1 static-tab-dropdown-favourite ${
                            sdlActiveClass == "favourite"
                              ? "active-tab-dropdown1"
                              : ""
                          }`}
                        >
                          {t("Favourites")}
                        </div>
                      </div>
                      {sdlActiveClass == "all" &&
                        studyGroupList.map((eleClass) => {
                          var selectedLevelForBook = favBookLevel.filter(
                            (o) =>
                              o.BSId == bookData.BookId &&
                              o.SchoolYear == selectedSchoolYear.SchoolYearId
                          );
                          var heartIcon = false;
                          if (selectedLevelForBook.length > 0) {
                            if (
                              selectedLevelForBook[0].StructureId.includes(
                                eleClass.StudyGroupId
                              )
                            ) {
                              heartIcon = true;
                            }
                          }
                          console.log(
                            "heartIcon",
                            heartIcon,
                            selectedLevelForBook
                          );

                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1"
                                onClick={() => {
                                  setdoDownload("");
                                  setSelectClass([
                                    {
                                      StudyGroupName:
                                        eleClass.StudyGroupName[
                                          eleClass.StudyGroupName[0].Lang ==
                                          lang
                                            ? 0
                                            : 1
                                        ].StudyGroupName,
                                      StudyGroupId: eleClass.StudyGroupId,
                                    },
                                  ]);
                                  setSelectGroupClass(
                                    eleClass.StudyGroupName[
                                      eleClass.StudyGroupName[0].Lang == lang
                                        ? 0
                                        : 1
                                    ].StudyGroupName
                                  );
                                  // alert(eleClass.StudyGroupId);
                                  setClassId(eleClass.StudyGroupId);
                                  setShowClassDropdown(false);
                                  // setShowClassData([]);
                                }}
                              >
                                {`${t("Class")} ${
                                  eleClass.StudyGroupName[
                                    eleClass.StudyGroupName[0].Lang == lang
                                      ? 0
                                      : 1
                                  ].StudyGroupName
                                }`}
                              </div>

                              {!heartIcon ? (
                                <div className="like-rows like-rows1">
                                  {favouriteLoading && favouriteLoadingId == eleClass.StudyGroupId ? <Loading type={`favourite`} /> :<img
                                    src={likeIcon}
                                    onClick={(e) => {
                                      setFavouriteLoadingId(eleClass.StudyGroupId)
                                      toggleCheckResource(
                                        e,
                                        bookData.BookId,
                                        eleClass.StudyGroupId,
                                        "yes",
                                        eleClass.StudyGroupName[
                                          eleClass.StudyGroupName[0].Lang ==
                                          lang
                                            ? 0
                                            : 1
                                        ].StudyGroupName
                                      );
                                    }}
                                  /> }
                                </div>
                              ) : (
                                <div className="like-rows like-rows1">
                                  {favouriteLoading && favouriteLoadingId == eleClass.StudyGroupId ? <Loading type={`favourite`} /> :<img
                                    src={likedIcon}
                                    onClick={(e) => {
                                      setFavouriteLoadingId(eleClass.StudyGroupId)
                                      toggleCheckResource(
                                        e,
                                        bookData.BookId,
                                        eleClass.StudyGroupId,
                                        "no",
                                        eleClass.StudyGroupName[
                                          eleClass.StudyGroupName[0].Lang ==
                                          lang
                                            ? 0
                                            : 1
                                        ].StudyGroupName
                                      );
                                    }}
                                  />}
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {sdlActiveClass == "favourite" &&
                      favStudyGroupList.length <= 0 ? (
                        <label className="empty-favourite-mesaage">
                          {t("No favourite classes added yet.")}
                        </label>
                      ) : (
                        ""
                      )}
                      {sdlActiveClass == "favourite" &&
                        favStudyGroupList.map((eleClass) => {
                          return (
                            <div className="main-class-container1">
                              <div
                                className="class-rows class-rows1"
                                onClick={() => {
                                  setdoDownload("");
                                  setSelectClass([
                                    {
                                      StudyGroupName:
                                        eleClass.StudyGroupName[
                                          eleClass.StudyGroupName[0].Lang ==
                                          lang
                                            ? 0
                                            : 1
                                        ].StudyGroupName,
                                      StudyGroupId: eleClass.StudyGroupId,
                                    },
                                  ]);
                                  setSelectGroupClass(
                                    eleClass.StudyGroupName[
                                      eleClass.StudyGroupName[0].Lang == lang
                                        ? 0
                                        : 1
                                    ].StudyGroupName
                                  );
                                  setClassId(eleClass.StudyGroupId);
                                  setShowClassDropdown(false);
                                  console.log("aamestudygroup", selectClass);
                                  // setShowClass(false);
                                  // setShowClassData([]);
                                }}
                              >
                                {`${t("Class")} ${
                                  eleClass.StudyGroupName[
                                    eleClass.StudyGroupName[0].Lang == lang
                                      ? 0
                                      : 1
                                  ].StudyGroupName
                                }`}
                              </div>

                              <div className="like-rows like-rows1">
                                <img
                                  src={likedIcon}
                                  onClick={(e) => {
                                    toggleCheckResource(
                                      e,
                                      bookData.BookId,
                                      eleClass.StudyGroupId,
                                      "no",
                                      eleClass.StudyGroupName[
                                        eleClass.StudyGroupName[0].Lang == lang
                                          ? 0
                                          : 1
                                      ].StudyGroupName
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    ""
                  )}
                  {showSDLPage && <DefaultSDLPage t={t} />}
                </>
              )}
              {!showSDLPage && openTab == 'sdl' && (
                <>
                  <div className="main__section-user-section">
                    <div className="main__section-user-section-left">
                      <button
                        className={`btns buttons sdl-book-resource-btn ${
                          bookSection == "resource" ? "active" : ""
                        }`}
                        onClick={() => setBookSection("resource")}
                      >
                        <span
                          className={`${
                            bookSection == "resource"
                              ? "mdi-open-in-new-sdl-book-resource-active"
                              : "mdi-open-in-new-sdl-book-resource"
                          }`}
                        ></span>
                        {t("Book Resources")}
                      </button>

                      {UserType == "T" && (
                        <button
                          className={`btns buttons sdl-book-resource-btn ${
                            bookSection != "resource" ? "active" : ""
                          }`}
                          onClick={() => setBookSection("book")}
                        >
                          <span
                            className={`${
                              bookSection != "resource"
                                ? "mdi-open-in-new-sdl-student-active"
                                : "mdi-open-in-new-sdl-student"
                            }`}
                          ></span>
                          {t("Students")}
                        </button>
                      )}
                    </div>
                    {UserType == "T" && (
                      <div className="main__section-user-section-right">
                        <div className="btn_refprogcontainer">
                         <button 
                         onClick={() => {
                          setrefreshBtn(refreshBtn + 1);
                         }}
                         className={`btns buttons sdl-view-progress-btn-refresh ${toggleProgress?'':'d-none'}`}
                        >
                        <span></span>
                        </button>

                        <button
                          className="btns buttons sdl-view-progress-btn"
                          onClick={() => {
                            
                            //setUnitLoading(true);
                            setdoDownload("");
                            //updateDispatch();
                            dispatch({
                              type: "SET_SELECTED_TYPE",
                              selectOptions: {
                                selectedToggle:!toggleProgress
                               },
                            });
                            setToggleProgress(!toggleProgress);
                            // setTimeout(() => {
                            //   setUnitLoading(false);
                            // }, 1500);
                          }}
                        >
                          {t("View Progress")}
                          <span
                            className={`${
                              toggleProgress
                                ? "mdi-open-in-new-sdl-view-progress-on"
                                : "mdi-open-in-new-sdl-view-progress-off"
                            }`}
                          ></span>
                        </button>
                        </div>
                        <div className="btn_downloadcontainer">                       
                        <button
                          className="btns buttons sdl-download-btn"
                          onClick={() => {
                            if (!window.cordova) {
                              window.gtag('event', 'SDL - Download report', {
                                'Book': selectedBookName,
                                'Book_Series': selectedBookSeriesName,
                                'Unit': retainChapterName!=""? retainChapterName: gatagDownloadName,
                                // 'user_Level': 'resourceDetails.MappedLevels[0].LevelName[0].LevelName',
                                'user_Level': selectGroupClass
                                ? selectGroupClass.split('')[0]
                                : selectClass.StudyGroupName.split('')[0],
                                'user_Role': UserType
                                })
                            } else {
                              sendGaEvent('SDL - Download report', {
                                'Book': selectedBookName,
                                'Book_Series': selectedBookSeriesName,
                                'Unit': retainChapterName!=""? retainChapterName: gatagDownloadName,
                                // 'user_Level': 'resourceDetails.MappedLevels[0].LevelName[0].LevelName',
                                'user_Level': selectGroupClass
                                    ? selectGroupClass.split('')[0]
                                    : selectClass.StudyGroupName.split('')[0],
                                'user_Role': UserType
                              })
                            }
                            setShowReportLoading(true);
                            setdoDownload(doDownload + 1);
                          }}
                          //TODO need support download button in later version
                          style={window.cordova?{display:"none"}:null}
                        >
                          {t("Download Report")}
                        </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="main__section-unit-container">
                    {bookSection == "resource" ? (
                      <UnitPage
                        unitLoading={unitLoading}
                        t={t}
                        {...props}
                        toggleProgress={toggleProgress}
                        refreshBtn = {refreshBtn}
                        selectedBookSeriesId={selectedBookSeriesId}
                        selectedBookId={selectedBookId}
                        ClassId={ClassId}
                        selectedClass={
                          selectGroupClass
                            ? selectGroupClass
                            : selectClass.StudyGroupName
                        }
                        showSDLPage={showSDLPage}
                        retainChapterFunc={retainChapterFunc}
                        setShowSDLPage={setShowSDLPage}
                        getScoreAndAverageClass={getScoreAndAverageClass}
                        selectedBookSeriesName={selectedBookSeriesName}
                        selectedBookName={selectedBookName}
                      />
                    ) : (
                      <UnitPageStudent
                        // downloadAction={downloadAction}
                        unitLoading={unitLoading}
                        t={t}
                        refreshBtn = {refreshBtn}
                        toggleProgress={toggleProgress}
                        selectedBookSeriesId={selectedBookSeriesId}
                        selectedBookId={selectedBookId}
                        ClassId={ClassId}
                        retainChapterFunc={retainChapterFunc}
                        selectedClass={
                          selectGroupClass
                            ? selectGroupClass
                            : selectClass.StudyGroupName
                        }
                        showSDLPage={showSDLPage}
                        setShowSDLPage={setShowSDLPage}
                        getScoreAndAverageClass={getScoreAndAverageClass}
                        selectedBookSeriesName={selectedBookSeriesName}
                        selectedBookName={selectedBookName}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            {openTab == 'resource' && 
            <>
            <button  onClick={() => {console.log("onClickTool Start");
                            onClickTool(selectedBookSeriesId, "link")
                          }} className="btn btn-primary resource-btn resource_teacher_btn">
              {t("Teachers' Resource Centre")}<i class="icon mdi mdi-open-in-new new-open-icon"></i></button>
            {tabLoader && <Loading type={`normal`} />}
            {!tabLoader && 
            <iframe
              className="resource_iframe"
              src={resourceIframeURL}
            >
            </iframe>}
            </>}
          </div>
        </div>
      </div>

      {downloadAction && (
        <DownloadReportExcel
          doDownload={doDownload}
          setdoDownload={setdoDownload}
          unitLoading={unitLoading}
          t={t}
          toggleProgress={toggleProgress}
          selectedBookSeriesId={selectedBookSeriesId}
          selectedBookId={selectedBookId}
          ClassId={ClassId}
          selectedClass={
            selectGroupClass ? selectGroupClass : selectClass.StudyGroupName
          }
          showSDLPage={showSDLPage}
          setShowSDLPage={setShowSDLPage}
          getScoreAndAverageClass={getScoreAndAverageClass}
          selectedBookName={selectedBookName}
          showReportLoading={showReportLoading}
          setShowReportLoading={setShowReportLoading}
        />
      )}
      {showReportLoading && <Loading />}

      {openTab != 'resource' &&
          <div className="dashboard-footer1">
            <Footer t={t}/>
          </div>
      }
    </>
  );
};

export default B2BBody;