/**
 * @generated SignedSource<<ac4cc515982aca2891d7b646be426447>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type GraphQLQueryStructureListQuery$variables = {|
  UserId: string,
  BookSeriesIds?: ?$ReadOnlyArray<?string>,
  BookSeriesId?: ?string,
  SchoolYearId: string,
  StudyGroupId: string,
|};
export type GraphQLQueryStructureListQuery$data = {|
  +user: ?{|
    +AdoptedBookSeries: ?$ReadOnlyArray<?{|
      +AdoptedBooks: ?$ReadOnlyArray<?{|
        +id: ?string,
        +BookId: ?string,
        +ResourceCenterStructure: ?$ReadOnlyArray<?{|
          +StructureId: ?string,
          +SummaryData: ?string,
          +StructureLang: ?$ReadOnlyArray<?{|
            +Lang: ?string,
            +StructureTitle: ?string,
          |}>,
          +ParentStructureId: ?string,
          +DisplaySequence: ?number,
          +ChildStructure: ?$ReadOnlyArray<?{|
            +SummaryData: ?string,
            +StructureId: ?string,
            +StructureLang: ?$ReadOnlyArray<?{|
              +Lang: ?string,
              +StructureTitle: ?string,
            |}>,
            +ParentStructureId: ?string,
            +DisplaySequence: ?number,
          |}>,
        |}>,
      |}>,
    |}>,
    +BelongsToStudyGroup: ?$ReadOnlyArray<?{|
      +StudyGroup: ?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
        +Level: ?{|
          +LevelName: ?$ReadOnlyArray<?{|
            +LevelName: ?string,
          |}>,
        |},
      |},
    |}>,
    +School: ?{|
      +StudyGroups: ?$ReadOnlyArray<?{|
        +StudyGroupId: ?string,
        +StudyGroupName: ?$ReadOnlyArray<?{|
          +StudyGroupName: ?string,
          +Lang: ?string,
        |}>,
      |}>,
    |},
  |},
|};
export type GraphQLQueryStructureListQuery = {|
  variables: GraphQLQueryStructureListQuery$variables,
  response: GraphQLQueryStructureListQuery$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "BookSeriesIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SchoolYearId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "StudyGroupId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "UserId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "UserId",
    "variableName": "UserId"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesId"
  }
],
v7 = {
  "kind": "Variable",
  "name": "SchoolYearId",
  "variableName": "SchoolYearId"
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "BookId",
  "storageKey": null
},
v11 = [
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "StudyGroupId",
    "variableName": "StudyGroupId"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StructureId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": (v11/*: any*/),
  "kind": "ScalarField",
  "name": "SummaryData",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Lang",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ResrouceCenterStructureLang",
  "kind": "LinkedField",
  "name": "StructureLang",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StructureTitle",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ParentStructureId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "DisplaySequence",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StudyGroupId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "studyGroupLang",
  "kind": "LinkedField",
  "name": "StudyGroupName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StudyGroupName",
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "pearsonLevelLang",
  "kind": "LinkedField",
  "name": "LevelName",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LevelName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = [
  {
    "kind": "Variable",
    "name": "BookSeriesId",
    "variableName": "BookSeriesIds"
  },
  {
    "kind": "Literal",
    "name": "FilterOnboarded",
    "value": false
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphQLQueryStructureListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "AdoptedBooks",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "ResourceCenterStructure",
                    "kind": "LinkedField",
                    "name": "ResourceCenterStructure",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResourceCenterStructure",
                        "kind": "LinkedField",
                        "name": "ChildStructure",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          (v12/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "BelongsToStudyGroup",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroup",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v21/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "GraphQLQueryStructureListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "bookSeries",
            "kind": "LinkedField",
            "name": "AdoptedBookSeries",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "book",
                "kind": "LinkedField",
                "name": "AdoptedBooks",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": (v11/*: any*/),
                    "concreteType": "ResourceCenterStructure",
                    "kind": "LinkedField",
                    "name": "ResourceCenterStructure",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResourceCenterStructure",
                        "kind": "LinkedField",
                        "name": "ChildStructure",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          (v12/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "studyGroupUserCustomType",
            "kind": "LinkedField",
            "name": "BelongsToStudyGroup",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroup",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "pearsonLevel",
                    "kind": "LinkedField",
                    "name": "Level",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "school",
            "kind": "LinkedField",
            "name": "School",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v21/*: any*/),
                "concreteType": "StudyGroup",
                "kind": "LinkedField",
                "name": "StudyGroups",
                "plural": true,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0615fbe477039caccad087c38cea1b70",
    "id": null,
    "metadata": {},
    "name": "GraphQLQueryStructureListQuery",
    "operationKind": "query",
    "text": "query GraphQLQueryStructureListQuery(\n  $UserId: String!\n  $BookSeriesIds: [String]\n  $BookSeriesId: String\n  $SchoolYearId: String!\n  $StudyGroupId: String!\n) {\n  user(UserId: $UserId) {\n    AdoptedBookSeries(BookSeriesId: $BookSeriesId) {\n      AdoptedBooks(SchoolYearId: $SchoolYearId) {\n        id\n        BookId\n        ResourceCenterStructure(SchoolYearId: $SchoolYearId, StudyGroupId: $StudyGroupId) {\n          StructureId\n          SummaryData(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)\n          StructureLang {\n            Lang\n            StructureTitle\n          }\n          ParentStructureId\n          DisplaySequence\n          ChildStructure {\n            SummaryData(StudyGroupId: $StudyGroupId, SchoolYearId: $SchoolYearId)\n            StructureId\n            StructureLang {\n              Lang\n              StructureTitle\n            }\n            ParentStructureId\n            DisplaySequence\n            id\n          }\n          id\n        }\n      }\n      id\n    }\n    BelongsToStudyGroup(SchoolYearId: $SchoolYearId) {\n      StudyGroup {\n        StudyGroupId\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        Level {\n          LevelName {\n            LevelName\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    School {\n      StudyGroups(BookSeriesId: $BookSeriesIds, SchoolYearId: $SchoolYearId, FilterOnboarded: false) {\n        StudyGroupId\n        StudyGroupName {\n          StudyGroupName\n          Lang\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "5c1f80e65dc6a8c6988120b37a127e47";

module.exports = ((node/*: any*/)/*: Query<
  GraphQLQueryStructureListQuery$variables,
  GraphQLQueryStructureListQuery$data,
>*/);
