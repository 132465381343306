import AssessmentGetLearnosityObjectMutation
    from "../Components/PopUpView/Page/AssessmentLayout/AssessmentTab/AssessmentGetLearnosityObjectMutation";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {config} from "../Config/config";
import {useTranslation} from "react-i18next";
import React from "react";

var useIosOpenPrizm = () => {
    var {t} = useTranslation();
    var OPEN_PRIZM_INAPPBROWSER_SETTING = "closebuttoncaption=BACK,hidenavigationbuttons=yes,location=no,hideurlbar=yes,toolbarposition=top,toolbar=no";
    var AUTO_SAVE_CODE = `if (!interval && params?.data.type === 'ICPLAYER_READY') {
                interval = setInterval(() => {
                    window.postMessage({
                        type: 'SAVE_BTN', payload:
                            {saveBtn: true}
                    }, '*');
                }, 30000);
            }`;
    var LANG = "EN";
    var user = useSelector((state) => state.UserReducer.user);
    var history = useHistory();
    var userType = user?.UserType;
    var activityName;
    var dataSet;
    var props;
    var data;
    var viewMode = false;
    const selectOptions = useSelector(
        (state) => state.EntryOfAssessmentReducer.selectOptions
    );


    var iosOpenPrizm = (inputProps, notificationTitle, resourceId, studyGroupId, schoolYearId, credential, inputData) => {
        props = inputProps;
        dataSet = {
            Lang: LANG,
            ResourceId: resourceId,
            StudyGroupId: studyGroupId,
            SchoolYearId: schoolYearId,
            Credential: credential
        };
        data = inputData;
        activityName = notificationTitle;
        AssessmentGetLearnosityObjectMutation(dataSet, mutationCallback);
    };

    var iosOpenPrizmSubmission = (iframeUrl, actName) => {
        activityName = actName;
        viewMode = true;
        initInAppBrowser(iframeUrl);
    }

    var mutationCallback = (response) => {
        console.log("mutationCallback response:", response);
        var prizmUrl;
        if (response.attemptSelfLearn) {
            console.log("attemptSelfLearn:", response.attemptSelfLearn)
            prizmUrl = response.attemptSelfLearn;
        } else {
            console.error("attemptSelfLearn is null");
        }
        initInAppBrowser(prizmUrl);
    }

    var initInAppBrowser = async (prizmUrl) => {
       // console.log("kk");
        var browserRef = window.cordova.InAppBrowser.open(prizmUrl, "_blank", OPEN_PRIZM_INAPPBROWSER_SETTING)
        var headerHtml;
        browserRef.addEventListener("loadstop", async function (e) {
            if (userType === 'S') {
                headerHtml = '<h3 class="main__iframe-heading">' + activityName + `</h3><div class="main__iframe-righ-section"><button class="btns buttons main__iframe-close-btn"><span class="mdi-open-in-new-sdl-close"></span>${t("Close")}</button></div>`;
            } else {
                headerHtml = '<h3 class="main__iframe-heading">' + activityName + `</h3><div class="main__iframe-righ-section"><button class="btns buttons main__iframe-result-btn"><span class="mdi-open-in-new-sdl-student-active"></span>${t("Class results")}</button><button class="btns buttons main__iframe-close-btn"><span class="mdi-open-in-new-sdl-close"></span>${t("Close")}</button></div>`;
            }
            var idlePopUp = `<div class="activity_notification__popup-overlay"><div class="idle_notification__popup"><h3 class="activity_notification__popup-heading">${t("Are you still working?")}</h3><span class="activity_notification__popup-border"></span><div class="activity_notification__popup-bottom"><button class="buttons btns activity_notification__popup-ok-btn" id="stay-here-btn">${t("Yes, stay here")}</button></div></div></div>`
            var loading = '<div class="sprint"><div class="splash-container"><div class="loader mx-auto" /></div></div>';
            var code = `
                    function closeIdlePopup() {
                        var element = document.getElementsByClassName("activity_notification__popup-overlay")[0];
                        element.style.display = "none";
                        setTimeout(() => {showIdlePopup()}, 1800000);
                    }
                    function showIdlePopup() {
                        var element = document.getElementsByClassName("activity_notification__popup-overlay")[0];
                        element.style.display = "inline";
                        setTimeout(()=> {checkIfIdleResponsed()}, 30000);
                    }
                    function checkIfIdleResponsed() {
                        var element = document.getElementsByClassName("activity_notification__popup-overlay")[0];
                        if (element.style.display != 'none') {
                            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));
                        }
                    }
                    var loading = document.createElement('div');
                    loading.classList.add('loading');
                    loading.innerHTML = '` + loading + `';
                    var body = document.querySelector('body');
                    var headerNav = document.createElement('div');
                    headerNav.classList.add('main__iframe-navigation');
                    headerNav.innerHTML = '` + headerHtml + `';
                    body.insertBefore(headerNav, body.firstChild);
                    body.insertBefore(loading, body.firstChild);
                    var idle = document.createElement('div');
                    idle.innerHTML = '${idlePopUp}';
                    body.insertBefore(idle, body.firstChild);
                    setTimeout(() => {showIdlePopup()}, 1800000);
                    document.getElementById("stay-here-btn")?.addEventListener('click', () => {closeIdlePopup()});
                    document.querySelector('.main__iframe-close-btn').addEventListener('click', function() {webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}))});
                    
                    var closeButton = document.getElementsByClassName("activity_notification__popup-back-btn")[0];
                    if (closeButton) {
                        closeButton.addEventListener('click', ()=>{webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}))});
                    }
                    var resultButton = document.getElementsByClassName("main__iframe-result-btn");
                    if (resultButton[0]) {
                        resultButton[0].addEventListener('click', ()=>{webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "openResult"}))});
                    }
                    async function clickedSubmit(){
                        loading.style.display = "block";
                        //setTimeout(()=>{
                        //    alert('${t('Successfully Submitted')}');
                        //    webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));
                        //}, 5000);
                    }
                    async function navigateAway(){
                        alert('${t('Successfully Submitted')}');
                        webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "closeIAB"}));
                    }
                    var interval;
                    window.addEventListener('message', async function (params) {
                        if (params?.data.type === 'SUBMIT_BTN_ACK') {
                            clickedSubmit();                            
                        }
                        if (params?.data.type === 'SUBMISSION_STATUS' && params?.data.payload.status === "Success") {
                            navigateAway();                            
                        }
                        ${viewMode ? AUTO_SAVE_CODE : ""}
                    });
                    
                `
            // if (config.hidden.prizmRecording.includes(data.IngestionId)) {

            //     var popUpDiv = `<div class="recording_activity_notification__popup-overlay">
            //     <div class="recording_activity_notification__popup">
            //     <h3 class="recording_activity_notification__popup-heading">
            //     We’re currently upgrading the recording function. Users won’t be able to submit their recording during this period.<br/> <br/> We apologise for any inconvenience caused.</h3><span class="recording_activity_notification__popup-border"></span><div class="recording_activity_notification__popup-bottom"><button class="buttons btns recording_activity_notification__popup-ok-btn">OK</button></div></div></div>`
            //     code = code + `
            //         var popUpDiv= document.createElement('div');
            //         popUpDiv.classList.add('test');
            //         popUpDiv.innerHTML = \`` + popUpDiv + `\`;
            //         body.insertBefore(popUpDiv, body.firstChild);
            //         document.getElementsByClassName("recording_activity_notification__popup-ok-btn")[0].onclick = function(){document.getElementsByClassName("recording_activity_notification__popup-overlay")[0].remove()};
            //         `
            // }
            browserRef.executeScript({
                code: code
            });
            browserRef.insertCSS({
                code: `
                    .recording_activity_notification__popup-ok-btn {
                        background: #0088CF !important;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 10px;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: center;
                        color: #fff;
                        padding: 7px 20px;
                    }
                    .recording_activity_notification__popup-overlay {
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0,0,0,0.85);
                        z-index: 2222222;
                    }
                    .recording_activity_notification__popup {
                        background: #ffffff;
                        max-width: 458px;
                        width: 95%;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding: 15px 24px;
                        border-radius: 12px;
                    }
                    .recording_activity_notification__popup-heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: normal;
                        color: #000000;
                        min-height: 60px;
                        max-height: 200px;
                        margin-top: 20px;
                    }
                    .recording_activity_notification__popup-border {
                        border-bottom: 1px solid #ddd;
                    }
                    .recording_activity_notification__popup-bottom {
                        display: flex;
                        align-items: end;
                        justify-content: end;
                        gap: 16px;
                    }
                    .main__iframe-navigation{
                        max-width: 1360px;
                        margin: 0 auto;
                        width: 100%;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 10px;
                    }
                    .main__iframe-heading{
                        font-style: normal;
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 25px;
                        color: #0088cf;
                        margin-left: 10px;
                    }
                    .main__iframe-righ-section{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 19px;
                        margin-right: 10px;
                    }
                    .mdi-open-in-new-sdl-close {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH7SURBVHgBtZdLTgJBEIarCjW+ElmqK70BLn07JzCeQG6gxldcOW59RDmBegL1BEziYytHcCXE1ZiYaFSmrcIQQWD6McO/oYeu6e+fZqqrQGBl/WJ2YKg3D0iLcq0idVPZmb2AFDR2/LDGK+Z5mAOEJwS6eN6cPpA5rIGH+4q1yWaV3jOfXrjhheCo8ZP7faXAb53BoLw14yEHnHNAvsP9zgY6g3+lVOSRAliKWSM3UO0rZk+LWUgRXBPSKoGCCYiXlQEjsLABXok3IAC9jAyYgkUqghLDowKYKdaAFZgfWLKJylsL1/zjH4CZ2hqwAbNKH29DKzLA+jejR7c+Iu2bLlDPAlvw+9ugF/pTYRPcxQAgBpwz66bxjeAWuIMBU7WA28K7YKAtuCM8RQMdwbHwFAzEgrXwBAa0YCO4aOz4jqseLoGh1BdNVvamn3RxpAuQPLYBi7A3ujKpBagDWxwg/6Utx9glsJEB7CJYawC7DI41QAnApaTVkFzBkseV7Xk/iQF0BTceIK7lmEYP7/NJwCKXHZAB8un1yB85V3CjbHagWq2uUFpgkc0OUCazLC+c7g+BEdjWQK115sBCWmAbA7ztAfX3fJ+1692lvXUBGxoovOwuXP91r/zW80VOEYxwR3/JNweQgmRdILXKzeYEKhXyU12Wt+fOZO4Hn9uHI1h9bAwAAAAASUVORK5CYII=);
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 19px;
                        width: 30px;
                        box-sizing: border-box;
                        display: block;
                    }
                    .btns {
                        background: #ffffff;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                    }
                    
                    .buttons {
                        cursor: pointer;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: center;
                        color: #ffffff;
                        border: none;
                    }
                    
                    .mdi-open-in-new-sdl-student-active {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAATCAYAAACHrr18AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFVSURBVHgBtZWBcYMwDEWVTuANygiMwAgZwRuEDcgGZQO6QbsBI5ANzAY0E6jiIh+KkMFpmnfn+PJl6csODgAPgognGj3emGh8wKshkwZtwhyDZ6EihdjVt9D2cLy24+9D1DRvhumJpoFGxdLI8xH28TxfeS5pBKpZ7xoTZxqyyx+ezc4VTuVErdE7vzOmYAVrg3eeR9jnohqQ5kXSWHUauUI+LjemjceEOWzouVySETrq0nhSJxH/3HiiO7EuGPFyy9glkmrR2JQwLnhNY8QCJq6VND8aiRMud7TeaMyhjYcccPnziAwiVhmFq5zcHONaHJEX+ryjL8O4U/kel5/M55pWnNTicrxOFbOwmmxZL/YMey5yVt1PmE9QDbTxVFYN4P2TGBL6ozSiTljpeNuRxCf0vxBr6ZtQz+KgxJIXD/g8PdfSr9T+MH/KYz8QvBjhH0jV+wWxKMtDYnJCdgAAAABJRU5ErkJggg==);
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 19px;
                        width: 30px;
                        display: block;
                    }
                    
                    @media only screen and (max-width: 640px) {
                        .main__iframe-close-btn {
                            align-self: end;
                        }
                        .main__iframe-result-btn {
                            align-self: start;
                        }
                        .main__iframe-navigation {
                            flex-direction: column;
                            padding-top: 0px;
                            height: auto;
                        }
                        .main__iframe-heading {
                            order: 2;
                            align-self: self-start;
                            margin-top: 45px;
                        }
                        .main__iframe-righ-section {
                            order: 1;
                            align-self: end;
                            display: flex;
                            flex-direction: column-reverse;
                            max-width: 641px;
                            width: 98%;
                            gap: 20px;
                        }
                    }
                    .main__iframe-result-btn {
                        box-sizing: border-box;
                        border-radius: 10px;
                        height: 43px;
                        min-width: 189px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;
                        background: #198cc5 !important;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        color: #ffffff;
                        padding: 0px 16px;
                    }
                    
                    .main__iframe-close-btn {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        text-align: center;
                        color: #198cc5 !important;
                        background: #ffffff;
                        height: 43px;
                        width: 74px;
                        padding: 0px 8px;
                        border-radius: 10px;
                        padding-right: 0px !important;
                    }
                    .activity_notification__popup {
                        background: #ffffff;
                        max-width: 450px;
                        width: 95%;
                        height: 220px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding: 10px 20px;
                        padding-bottom: 45px;
                        border-radius: 12px;
                    }
                    .activity_notification__popup-top-heading {
                        color: #000;
                        font-size: 18px;
                        font-weight: 800;
                        margin-top: 20px;
                    }
                    .activity_notification__popup-border {
                        border-bottom: 1px solid #ddd;
                    }
                    .activity_notification__popup-heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 36px;
                        color: #000000;
                        min-height: 60px;
                        max-height: 200px;
                        padding-bottom: 16px;
                    }
                    .activity_notification__popup-border {
                        border-bottom: 1px solid #ddd;
                    }
                    .activity_notification__popup-bottom {
                        display: flex;
                        align-items: end;
                        justify-content: end;
                        gap: 16px;
                    }
                    .activity_notification__popup-back-btn {
                        background: #198cc5 !important;
                        border-radius: 10px;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: center;
                        color: #ffffff;
                        padding: 7px 20px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                    }
                    .prizmIframe .loading {
                        position: absolute;
                    }
                    .loading {
                        position: fixed;
                        display:none;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #3d3c3ca1;
                        z-index: 99999;
                    }
                    .sprint {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -60px;
                        margin-left: -60px;
                        width: 120px;
                        height: 120px;
                        border-radius: 10px;
                        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
                        background-color: #fff;
                    }
                    .sprint .splash-container {
                        margin: 35px auto;
                    }
                    .sprint .splash-container {
                        margin: 35px auto;
                    }
                    .splash-container {
                        text-align: center;
                    }
                    .loader {
                        border: 6px solid #f3f3f3;
                        border-radius: 50%;
                        border-top: 6px solid #007fa3;
                        width: 50px;
                        height: 50px;
                        -webkit-animation: spin 2s linear infinite;
                        animation: spin 2s linear infinite;
                        margin:auto;
                    }
                    .idle_notification__popup {
                        background: #ffffff;
                        max-width: 450px;
                        width: 95%;
                        height: 220px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding: 50px 20px;
                        padding-bottom: 45px;
                        border-radius: 12px;
                    }
                    .activity_notification__popup-heading {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 36px;
                        color: #000000;
                        min-height: 60px;
                        max-height: 200px;
                        padding-bottom: 16px;
                    }
                    .activity_notification__popup-border {
                        border-bottom: 1px solid #ddd;
                    }
                    .activity_notification__popup-bottom {
                        display: flex;
                        align-items: end;
                        justify-content: end;
                        gap: 16px;
                    }
                    .activity_notification__popup-ok-btn {
                        background: #0088cf !important;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 10px;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: center;
                        color: #fff;
                        padding: 7px 20px;
                    }
                    .activity_notification__popup-overlay {
                        display: none;
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, 0.85);
                        z-index: 2;
                    }
                `
            })
        });
        browserRef.addEventListener("exit", function (e) {
            browserRef.close();
            browserRef = null;
        });

        browserRef.addEventListener("message", async function (params) {
            console.log("HERE IS MESSAGE:", params)
            if (params.data.action === "closeIAB") {
                browserRef.close();
                history.push("/s/studygroup/" + props.match.params.id + "/rcenter");
            }
            if (params.data.action === "openResult") {
                console.log("selectOptions ", selectOptions)
                browserRef.close();
                console.log("HERE IS PROPS:", props);
                var path = "/a/studygroup/" +
                    (data?.BookSeriesId ? data.BookSeriesId : props.match.params.id) +
                    "/assessment/" +
                    "prizm&" +
                    (props.selectedBookId ? props.selectedBookId : props.prizmBookId) +
                    "/" +
                    dataSet.ResourceId +
                    "/result";
                console.log("HERE IS PATH:", path);
                history.push({
                    pathname: path,
                    state: {
                        group_id: dataSet.StudyGroupId,
                        leftMenu: "",
                        bookVal: props.selectedBookId ? props.selectedBookId : props.prizmBookId,
                    },
                });
                // history.push("/m")
                console.log("HISTORY DONE")
                //alert(tabClassId);
                // dispatch({
                //     type: "SET_SELECTED_TYPE",
                //     selectOptions: {
                //         selectedStudyGroupId: dataSet.StudyGroupId,
                //         selectedStudyGroupName: selectOptions.selectedStudyGroupName,
                //         selectedResourceName:selectOptions.selectedResourceName,
                //         selectecResourceTab:selectOptions.selectecResourceTab,
                //         selectedToggle: selectOptions.selectedToggle,
                //         setselectedResAssignId: "",
                //         selectedReportType: "",
                //         selectedAssessType: "",
                //         firstLevel: "", //activeFetchId,
                //         secondLevel: "", //bredCrumRes[1],
                //         thirdLevel: "", //bredCrumRes[2],
                //     },
                // });
            }
            if (params.data.action === "SUBMIT_BTN_ACK") {
                browserRef.close();
            }

        });
    }

    return {iosOpenPrizm, iosOpenPrizmSubmission};
}

export default useIosOpenPrizm;