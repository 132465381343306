import environment from "../../../Environment";
import React, { useState, useEffect, useRef } from "react";
import "./NotificationPopUp.scss";
import ResourceIconMapping from "../../../Components/Feature/Function/ResourceIconMappingPEC";
import { config } from "../../../Config/config";
import DashboardStudyGroup from "../../../Services/api/GraphQL/Query/DashboardStudyGroup";
import ReadResourceMutation from "../../../Components/Feature/Function/ReadResourceMutation";
import ResourcesSearchCheckAccessRight from "../../../Services/api/GraphQL/Query/ResourcesSearchCheckAccessRight";
import DeleteNotificationMutation from "../../../Services/api/GraphQL/Mutation/DeleteNotificationMutation";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "../../../assets/img/pec/all-images/icon-delete.png";
import EditIcon from "../../../assets/img/pec/all-images/icon-edit.png";
import DefaultIcon from "../../../assets/img/pec/all-images/icons/icon-interactive-blue.png";
import VidoeLightBox from "../../../Components/Feature/VideoLightBox";
import ImageLightBox from "../../../Components/Feature/ImageLightBox";
import NotificationPopUpConfirmation from "./NotificationPopUpConfirmation";
import MessageLightBox from "../../../Components/Feature/MessageLightBox";
import { fetchQuery, graphql } from "relay-runtime";
import Loading from "../../../Components/Loading/PEC/Loading";
import HandleSSOtoOtherPlatform from "../../../Services/Common/HandleSSOtoOtherPlatform";

import {
  isMobile,
  isBrowser,
  isTablet,
  isMobileOnly,
  osName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isOpera,
} from "react-device-detect";
import useInAppBrowser from "../../../hooks/useInAppBrowser";
import useIosOpenPrizm from "../../../hooks/useIosOpenPrizm";
import useEncryption from "../../../hooks/useEncryption";
import {useHistory} from "react-router-dom";
import useIosOpenLearnosity from "../../../hooks/useIosOpenLearnosity";
const NotificationPopUp = (props) => {
  const {
    notificationTitle,
    notificationResourceInfo,
    selectClass,
    t,
    showNotificatioPopUp,
    setShowNotificatioPopUp,
    notificationPopupOverlay,
    setNotificationPopupOverlay,
    refreshhandbook,
    HandbookPopupFlag,
  } = props;
  console.log("properties", props)
  //Test ing
  const dispatch = useDispatch();
  //let historyPop = useHistory();
  const userData = useSelector((state) => state.UserReducer.user);
  const UserType = userData?.UserType;
  const role = userData?.UserType;
  const logUserId = userData?.UserId;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const notification_popup_ref = useRef(null);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const [accessLoading, setaccessLoading] = useState(false);
  const selectOptions = useSelector(
    (state) => state.EntryOfAssessmentReducer.selectOptions
  );
  console.log("SURESH", selectOptions)
  const history = useHistory();
  const [accessFlag, setAccessFlag] = useState(false);

  //opening Resource
  const [videoLightBoxOpen, setVideoLightBoxOpen] = useState(null);
  const [imageLightBoxOpen, setImageLightBoxOpen] = useState(null);
  const [fetchingEPSResourceToken, setfetchingEPSResourceToken] =
    useState(false);
  // const [resourceDataLoading, setresourceDataLoading] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [showConfirmDeletePopUp, setShowConfirmDeletePopUp] = useState(false);
  const { systemBrowserOpenLink, openLink } = useInAppBrowser();
  const {iosOpenPrizm} = useIosOpenPrizm();
  const {encrypt, decryptToLocal} = useEncryption();
  const userCredential = window.localStorage.getItem("userCre") ? decryptToLocal(window.localStorage.getItem("userCre")) : null;
  const { openLearnosity } = useIosOpenLearnosity();


  const deleteNotification = (sharingId) => {
    const callback = (result) => {
      props.toggleNotificationPopUp("delete");
      if (HandbookPopupFlag) {
        refreshhandbook(HandbookPopupFlag);
      }
    };
    //props.toggleNotificationPopUp("delete");
    //refreshhandbook(HandbookPopupFlag);
    DeleteNotificationMutation(sharingId, callback);
  };

  const NotificationPopUpGetEPSTokenQuery = graphql`
    query NotificationPopUpGetEPSTokenQuery($ResourceId: [String]!) {
      resourceSearch(ResourceId: $ResourceId) {
        Resources {
          ResourceDownloadUrl
          ResourceFileNameIfAny
          ResourceAccessUrl
          EpsAccessToken
        }
      }
    }
  `;

  const NotificationPopUpGetSchoolBookSeriesListPecQuery = graphql`
    query NotificationPopUpGetSchoolBookSeriesListPecQuery(
      $UserId: String!
      $SchoolYearId: String!
    ) {
      user(UserId: $UserId) {
        AdoptedBookSeries {
          BookSeriesId
          LeapBookSeriesId
          IngestionId
          BookSeriesName {
            Lang
            BookSeriesName
          }
          IsB2CBookSeries
          BookSeriesImage {
            FilePath
          }
          BookSeriesBGImage {
            FilePath
          }
          AdoptedBooks(SchoolYearId: $SchoolYearId) {
            id
            BookId
            BookName {
              Lang
              BookName
            }
            BookImage {
              FilePath
            }
          }
        }
      }
    }
  `;
  const NotificationPopUpGetEPSTokenStudentQuery = graphql`
    query NotificationPopUpGetEPSTokenStudentQuery(
      $ResourceId: [String]!
      $StudyGroupId: String!
      $SchoolYearId: String!
    ) {
      resourceSearch(
        ResourceId: $ResourceId
        StudyGroupId: $StudyGroupId
        SchoolYearId: $SchoolYearId
      ) {
        Resources {
          ResourceDownloadUrl
          ResourceFileNameIfAny
          ResourceAccessUrl
          EpsAccessToken
        }
      }
    }
  `;

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        notification_popup_ref.current !== null &&
        !notification_popup_ref.current.contains(e.target)
      ) {
        // document.querySelector(
        //   ".ReactModal__Content.ReactModal__Content--after-open"
        // )
        //   ? (document.querySelector(
        //     ".ReactModal__Content.ReactModal__Content--after-open"
        //   ).style.overflow = "auto")
        //   : "";
        if(document.querySelector(".ReactModal__Content.ReactModal__Content--after-open")){
          document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
            el.setAttribute("style", "overflow: auto");    
          })
        }
        setShowNotificatioPopUp(false);
        setNotificationPopupOverlay(false);
      }
    };
    if (showNotificatioPopUp) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showNotificatioPopUp]);

  // useEffect(() => {
  //   if (role == "S") {
  //     const variables = {
  //       UserId: userData.UserId,
  //       SchoolYearId: selectedSchoolYear.SchoolYearId,
  //       FilterOnboarded: false,
  //     };
  //     DashboardStudyGroup(variables, role).then((data) => {
  //       const {
  //         user: { BelongsToStudyGroup = [{}] },
  //       } = data || {};

  //       let bookseries = [];
  //       BelongsToStudyGroup.map((obj, i) => {
  //         if (
  //           bookseries
  //             .map((b) => b.BookSeriesId)
  //             .indexOf(obj.StudyGroup.BookSeries.BookSeriesId) == -1
  //         ) {
  //           bookseries.push(obj.StudyGroup.BookSeries);
  //         }
  //       });
  //       let filterList = bookseries.filter(
  //         (o) => o.IsB2CBookSeries == false && o.IngestionId != null
  //       );
  //       let defaultGroup = BelongsToStudyGroup.filter((obj) => {
  //         return (
  //           (obj.StudyGroup.BookSeries.IngestionId != null ||
  //             obj.StudyGroup.BookSeries.IngestionId != "")
  //         );
  //       });
  //       setStudentClassId(defaultGroup[0].StudyGroupId);

  //     });
  //   }
  // }, []);

  const getEPSToken = (resId, options, tabClassId, callback) => {
    setfetchingEPSResourceToken(true);
    var value = {
      ResourceId: resId,
    };
    try {
      if (UserType == "S") {
        var variables = {
          StudyGroupId: tabClassId,
          ResourceId: resId,
          SchoolYearId: selectedSchoolYear.SchoolYearId,
        };
        fetchQuery(
          environment,
          NotificationPopUpGetEPSTokenStudentQuery,
          variables
        ).then((data) => {
          if (options.platform == "Web") {
            forWebAction(data, options, callback);
          }
          if (options.platform == "App") {
            // this.forAppAction(data, options, callback);
            callback(data);
          }
        });
      } else {
        fetchQuery(environment, NotificationPopUpGetEPSTokenQuery, value).then(
          (data) => {
            if (options.platform == "Web") {
              forWebAction(data, options, callback);
            }
            if (options.platform == "App") {
              // this.forAppAction(data, options, callback);
              callback(data);
            }
          }
        );
      }
    } catch (e) {
      setfetchingEPSResourceToken(false);
      //setLoading(false);
      //setresourceDataLoading(false);

      window.alert(e.message);
    }
  };

  const forWebAction = (data, options, callback) => {
    try {
      switch (options.restype) {
        case "IMAGE":
          if (options.actionType == "download") {
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            a.download = options.actionType;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again

            setfetchingEPSResourceToken(false);
          } else {
            callback(data.resourceSearch.Resources[0].ResourceAccessUrl);
            // var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
          }

          break;
        default:
          if (options.actionType == "download") {
            var a = document.createElement("a");
            a.href = data.resourceSearch.Resources[0].ResourceAccessUrl;
            a.target = "_blank";
            a.download = options.actionType;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox

            a.click();
            a.remove(); //afterwards we remove the element again
          } else {
            var url = data.resourceSearch.Resources[0].ResourceAccessUrl;
            if (window.cordova) {
              systemBrowserOpenLink(url);
            } else {
              window.open(url, "_blank");
            }
          }

          setfetchingEPSResourceToken(false);
          break;
      }
    } catch (e) {
      setfetchingEPSResourceToken(false);
      window.alert(e.message);
    }
  };

  const handleVideoBox = (data) => {
    setTimeout(() => {
      if (document.querySelector("#video")) {
        if (document.querySelector(".notifications__popup-overlay-handbook")) {
          document.querySelector(".notifications__popup-overlay-handbook")
            ? document
              .querySelector(".notifications__popup-overlay-handbook")
              .setAttribute("style", "z-index: 99995 !important")
            : "";
        }
      }
    });
    setVideoLightBoxOpen(
      data
        ? {
          type: data.type,
          videoPath: data.Linkage,
          isOpen: true,
        }
        : null
    );
  };
  const handleImageBox = (id, path) => {
    setImageLightBoxOpen(id ? { name: id, image: path } : id);
  };

  const zIndexforPopUp = () => {
    if (document.querySelector(".whole-tab")) {
      document
        .querySelector(".notifications__popup-overlay-handbook")
        .setAttribute("style", "z-index: 99995 !important");

      document
        .querySelector(".ReactModal__Overlay.ReactModal__Overlay--after-open")
        .setAttribute("style", "z-index: 99995 !important");
    } else if (document.querySelector(".main__handbooks-pagination")) {
      document
        .querySelector(".notifications__popup-overlay")
        .setAttribute("style", "z-index: 99991 !important");

      document
        .querySelector(".ReactModal__Overlay.ReactModal__Overlay--after-open")
        .setAttribute("style", "z-index: 99995 !important");
    } else if (document.querySelector(".main__section-unit-grid-heading")) {
      document
        .querySelector(".notifications__popup-overlay-handbook")
        .setAttribute("style", "z-index: 99995 !important");

      document
        .querySelector(".ReactModal__Overlay.ReactModal__Overlay--after-open")
        .setAttribute("style", "z-index: 99995 !important");
    }
  };

  const checkDeviceToOpenOrDownload = async (
    rdata,
    selectedClass,
    tabClassId,
    structureId,
    type
  ) => {
    let variables = {
      IngestionId: [rdata.IngestionId],
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      StudyGroupId: role == "S" ? tabClassId : "",
    };

    setaccessLoading(true);

    ResourcesSearchCheckAccessRight(variables, role).then((data, error) => {
      console.log("DATA, " + JSON.stringify(rdata));
      const {
        resourceSearch: { Resources = [{}] },
      } = data || {};
      if (Resources?.length == 0 || Resources == null) {
        //if no access
        setaccessLoading(false);
        setAccessFlag(true);
        zIndexforPopUp();
      } else {
        setaccessLoading(false);
        checkDeviceToOpenOrDownloadbk(rdata, selectedClass, tabClassId,structureId, type);
      }
    });
  };
  const checkDeviceToOpenOrDownloadold = async (
    rdata,
    selectedClass,
    tabClassId,
    type
  ) => {
    let variables = {
      IngestionId: [rdata.IngestionId],
    };

    var values = {
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
    };
    setaccessLoading(true);
    await fetchQuery(
      environment,
      NotificationPopUpGetSchoolBookSeriesListPecQuery,
      values
    ).then((data) => {
      var listArrayTemp = [];
      data.user.AdoptedBookSeries.map((obj, index) => {
        if (obj.IngestionId == null || obj.IngestionId == "") return;
        listArrayTemp.push(obj);
      });
      listArrayTemp.sort(function (a, b) {
        return a.DisplaySequence - b.DisplaySequence;
      });

      var accessBooks = [];
      listArrayTemp.map((bobj, bindex) => {
        bobj.AdoptedBooks.map((abook, aindex) => {
          accessBooks.push(abook.BookId);
        });
      });
      if (accessBooks.length > 0) {
        if (accessBooks.includes(rdata.BookId)) {
          setaccessLoading(false);
          checkDeviceToOpenOrDownloadbk(rdata, selectedClass, tabClassId, type);
        } else {
          setaccessLoading(false);
          // props.toggleNotificationPopUp("no");
          setAccessFlag(true);
          // if (
          //   document.querySelector(
          //     ".ReactModal__Overlay.ReactModal__Overlay--after-open"
          //   )
          // ) {
          //   document
          //     .querySelector(
          //       ".ReactModal__Overlay.ReactModal__Overlay--after-open"
          //     )
          //     .setAttribute("style", "z-index: 99995 !important");
          // }
          // if (props.isDashboard) {
          //   if (
          //     document.querySelector(
          //       ".ReactModal__Overlay.ReactModal__Overlay--after-open"
          //     )
          //   ) {
          //     document
          //       .querySelector(
          //         ".ReactModal__Overlay.ReactModal__Overlay--after-open"
          //       )
          //       .setAttribute("style", "z-index: 99996 !important");
          //   }
          // }
          zIndexforPopUp();
        }
      } else {
        setaccessLoading(false);
        // props.toggleNotificationPopUp("no");
        setAccessFlag(true);
        // if (
        //   document.querySelector(
        //     ".ReactModal__Overlay.ReactModal__Overlay--after-open"
        //   )
        // ) {
        //   document
        //     .querySelector(
        //       ".ReactModal__Overlay.ReactModal__Overlay--after-open"
        //     )
        //     .setAttribute("style", "z-index: 99995 !important");
        // }
        // if (props.isDashboard) {
        //   if (
        //     document.querySelector(
        //       ".ReactModal__Overlay.ReactModal__Overlay--after-open"
        //     )
        //   ) {
        //     document
        //       .querySelector(
        //         ".ReactModal__Overlay.ReactModal__Overlay--after-open"
        //       )
        //       .setAttribute("style", "z-index: 99996 !important");
        //   }
        // }
        zIndexforPopUp();
      }
    });

    // ResourcesSearchCheckAccessRight(variables).then((data, error) => {
    //   const {
    //     resourceSearch: { Resources = [{}] },
    //   } = data || {};
    //   if (Resources?.length == 0 || Resources == null) {
    //   } else {
    //   }
    // });
  };
  const checkDeviceToOpenOrDownloadbk = (
    data,
    selectedClass,
    tabClassId,
    structureId,
    type
  ) => {
    //alert(window.location.href)

    


 
    if (type == "open") {
      if (UserType == "S") {
        var variables = {
          StructureId: structureId,
          ResourceId: data.ResourceId,
          SchoolYearId: selectedSchoolYear.SchoolYearId,
        };
        ReadResourceMutation(variables, (data) => { });
      }
    }
    if (window.cordova){
      console.log('inside cordova',data)
      if (type === "open") {
        switch (data.ResourceType) {
          case "WEB_LINK":
          case "VIDEO":
          case "DOCUMENT":
          case "AUDIO":
          case "OTHER":
            if (data.FileSource === "eps") {
              // eps download or open
              getEPSToken(data.ResourceId, {actionType: "open", restype: data.ResourceType, platform: "Web",}, tabClassId
              );
            } else {
              systemBrowserOpenLink(data.ResourceAccessUrl);
            }
            break;
          case "EBOOK4":
            systemBrowserOpenLink(data.ResourceAccessUrl);
            break;
          case "IMAGE":
            if (data.FileSource === "eps") {
              // eps download or open
              getEPSToken(
                  data.ResourceId,
                  {
                    actionType: "open",
                    restype: data.ResourceType,
                    platform: "Web",
                  },
                  tabClassId,
                  (path) => {
                    //imagebox

                    setImageLightBoxOpen({
                      id: data.ResourceId,
                      image: path,
                      obj: data,
                    });
                    setfetchingEPSResourceToken(false);
                  }
              );
            } else {
              systemBrowserOpenLink(data.ResourceAccessUrl);
            }
            break;

          case "YOUTUBE":
            handleVideoBox({ ...data, type: "youtube", obj: data });
            break;
          case "VIMEO":
            handleVideoBox({ ...data, type: "vimeo", obj: data });
            break;
          case "LEARNOSITY":
            var sarg = data.BookSeriesId;
            // dispatch({
            //   type: "SET_SELECTED_TYPE",
            //   selectOptions: {
            //     selectedStudyGroupId: tabClassId,
            //     selectedStudyGroupName: selectedClass,
            //     setselectedResAssignId: "",
            //     selectedReportType: "",
            //     selectedAssessType: "",
            //     firstLevel: "", //activeFetchId,
            //     secondLevel: "", //bredCrumRes[1],
            //     thirdLevel: "", //bredCrumRes[2],
            //     selectedToggle: selectOptions.selectedToggle,
            //   },
            // });
            dispatch({
              type: "SET_SELECTED_TYPE",
              selectOptions: {
                selectedStudyGroupId: tabClassId,
                selectedStudyGroupName: selectedClass,
                setselectedResAssignId: "",
                selectedReportType: "",
                selectedAssessType: "",
                firstLevel: "", //activeFetchId,
                secondLevel: "", //bredCrumRes[1],
                thirdLevel: "", //bredCrumRes[2],
                selectedToggle: selectOptions.selectedToggle,
                backpathRes: window.location.href.indexOf("/rcenter") != -1 ? "SDL" : "Dashboard"
              }
            });
            const pathname = "/a/studygroup/" +
                sarg +
                "/assessment/" +
                "qs&" +
                data.BookId +
                "/" +
                data.ResourceId +
                "/start"
            openLearnosity(window.location.hostname, pathname)
            break;
          case "PRIZM":
            dispatch({
              type: "SET_SELECTED_TYPE",
              selectOptions: {
                selectedStudyGroupId: tabClassId,
                selectedStudyGroupName: selectedClass,
                setselectedResAssignId: "",
                selectedReportType: "",
                selectedAssessType: "",
                firstLevel: "", //activeFetchId,
                secondLevel: "", //bredCrumRes[1],
                thirdLevel: "", //bredCrumRes[2],
                selectedToggle: selectOptions.selectedToggle,
                backpathRes: window.location.href.indexOf("/rcenter") != -1 ? "SDL" : "Dashboard"
              },
            });
            if (isIOS) {
              iosOpenPrizm(props, props.notificationTitle, data.ResourceId, tabClassId, selectedSchoolYear.SchoolYearId, encrypt(userCredential), data);
              return;
            }
            var sarg = data.BookSeriesId;
            history.push({
              pathname:
                  "/a/studygroup/" +
                  sarg +
                  "/assessment/prizm&" +
                  data.BookId +
                  "/" +
                  data.ResourceId +
                  "/start"
            })
            break;
          case "INTERACTIVE":
            const cookie = data.ResourceAccessUrl.split("/index.html?")[1];
            const cookieKey = cookie.split('=')[0];
            const browserRef = window.cordova.InAppBrowser.open(data.ResourceAccessUrl, "_blank", "hidden=yes");
            browserRef.addEventListener("loadstop", function() {
              browserRef.executeScript({
                code: `if (!document.cookie || document.cookie.indexOf('${cookieKey}') === -1) {
                            document.cookie = '${cookie}';
                            location.reload();
                        } else {
                            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action: "showIAB"}));
                        }`
              })
            })

            browserRef.on('message', function(params) {
              if (params.data.action === "showIAB") {
                browserRef.show();
              }
            })

            break;
          default:
            // if (data.FileSource == "eps") {
            //   // eps download or open
            //   getEPSToken(data.ResourceId, {
            //     actionType: "open",
            //     restype: data.ResourceType,
            //     platform: "Web",
            //   });
            // } else {
              systemBrowserOpenLink(data.ResourceAccessUrl);
            // }
            break;
        }
      }
    }
    if (
      !window.cordova &&
      (isBrowser ||
        isChrome ||
        isFirefox ||
        isSafari ||
        isOpera ||
        isIE ||
        isEdge ||
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        ))
    ) {
      console.log('not cordova',data)
      if (isChrome) {
      }
      if (isFirefox) {
      }
      if (isSafari) {
      }
      if (isOpera) {
      }
      if (isIE) {
      }
      if (isEdge) {
      }
      if (
        navigator.userAgent.match(
          /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
        )
      ) {
      }
      //check is app only and no web
      if (type == "open") {
        switch (data.ResourceType) {
          case "WEB_LINK":
          case "VIDEO":
          case "DOCUMENT":
          case "AUDIO":
          case "OTHER":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "Web",
                },
                tabClassId
              );
            } else {
              window.open(data.ResourceAccessUrl, "_blank");
            }
            break;
            case "MASTERWEBITE":
              return HandleSSOtoOtherPlatform("WEB", {
                platform: "web",
                url: data.ResourceAccessUrl,
              });
             break;
          case "EBOOK4":
            window.open(data.ResourceAccessUrl, "_blank");
            break;
          case "IMAGE":
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "Web",
                },
                tabClassId,
                (path) => {
                  //imagebox

                  setImageLightBoxOpen({
                    id: data.ResourceId,
                    image: path,
                    obj: data,
                  });
                  setfetchingEPSResourceToken(false);
                }
              );
            } else {
              window.open(data.ResourceAccessUrl, "_blank");
            }
            break;

          case "YOUTUBE":
            handleVideoBox({ ...data, type: "youtube", obj: data });
            break;
          case "VIMEO":
            handleVideoBox({ ...data, type: "vimeo", obj: data });
            break;
          case "LEARNOSITY":
            var sarg = data.BookSeriesId;
            dispatch({
              type: "SET_SELECTED_TYPE",
              selectOptions: {
                selectedStudyGroupId: tabClassId,
                selectedStudyGroupName: selectedClass,
                setselectedResAssignId: "",
                selectedReportType: "",
                selectedAssessType: "",
                firstLevel: "", //activeFetchId,
                secondLevel: "", //bredCrumRes[1],
                thirdLevel: "", //bredCrumRes[2],
                selectedToggle: selectOptions.selectedToggle,
                backpathRes: window.location.href.indexOf("/rcenter") != -1 ? "SDL" : "Dashboard"
              },
            });
            const newURL =
              "/a/studygroup/" +
              sarg +
              "/assessment/qs&" +
              data.BookId +
              "/" +
              data.ResourceId +
              "/start";

              history.push(
                "/a/studygroup/" +
              sarg +
              "/assessment/qs&" +
              data.BookId +
              "/" +
              data.ResourceId +
              "/start"
              );
            //window.open(newURL, "_self");

            // var sarg = data.BookSeriesId;

            // props.history.push({
            //   pathname:
            //   "/a/studygroup/" +
            //   sarg +
            //   "/assessment/" +
            //   "qs&" +data.BookId+
            //   "/" +
            //   data.ResourceId +
            //   "/start",
            //   state: { backURL: "SDL", previewURL: "",group_id: tabClassId, leftMenu: "", bookVal : data.BookId},
            // });

            // var newURL = window.location.pathname.replace(
            //   props.match.url,
            //   "/a/studygroup/" +
            //     sarg +
            //     "/assessment/" +
            //     "qs" +
            //     "/" +
            //     data.ResourceId +
            //     "/start"
            // );
            // window.open(newURL, "_blank");

            break;
          case "PRIZM":
            dispatch({
              type: "SET_SELECTED_TYPE",
              selectOptions: {
                selectedStudyGroupId: tabClassId,
                selectedStudyGroupName: selectedClass,
                setselectedResAssignId: "",
                selectedReportType: "",
                selectedAssessType: "",
                firstLevel: "", //activeFetchId,
                secondLevel: "", //bredCrumRes[1],
                thirdLevel: "", //bredCrumRes[2],
                selectedToggle: selectOptions.selectedToggle,
                backpathRes: window.location.href.indexOf("/rcenter") != -1 ? "SDL" : "Dashboard"
              },
            });
            var sarg = data.BookSeriesId;
            //console
            const newURLP =
              "/a/studygroup/" +
              sarg +
              "/assessment/prizm&" +
              data.BookId +
              "/" +
              data.ResourceId +
              "/start";
            // window.open(newURLP, "_blank");
            setShowNotificatioPopUp(false);
            // setNotificationPopupOverlay(false);
           // window.open(newURLP, "_self");

           history.push(
              "/a/studygroup/" +
                sarg +
                "/assessment/prizm&" +
              data.BookId +
              "/" +
              data.ResourceId +
              "/start"
            );
            //window.open(newURL, "_blank");
            break;
          default:
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "open",
                  restype: data.ResourceType,
                  platform: "Web",
                },
                tabClassId
              );
            } else {
              window.open(data.ResourceAccessUrl, "_blank");
            }
            break;
        }
      }

      if (type == "download") {
        switch (data.ResourceType) {
          case "WEB_LINK":
          case "VIDEO":
          case "DOCUMENT":
          case "AUDIO":
          case "EBOOK4":
          case "LEARNOSITY":
          case "PRIZM":
          case "OTHER":
          case "IMAGE":
          default:
            if (data.FileSource == "eps") {
              // eps download or open
              getEPSToken(
                data.ResourceId,
                {
                  actionType: "download",
                  restype: data.ResourceType,
                  platform: "Web",
                },
                tabClassId
              );
            } else {
              var a = document.createElement("a");
              a.href = data.ResourceAccessUrl;
              a.download = true;
              a.target = "_blank";
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
            }
            break;
        }
      }
      //only web
    }
  };

  return (
    <>
      {accessFlag && (
        <MessageLightBox
          isOpen={accessFlag}
          type="accessRight"
          title={""}
          message={t("You don't have access right to this resource.")}
          onClose={() => {
            setAccessFlag(true);
          }}
          customButton={() => {
            return (
              <>
                <span className="view_notification__popup-border"></span>
                <button
                  className="buttons btns view_notification__popup-save-btn access__right-btn"
                  onClick={() => {
                    setAccessFlag(true);
                  }}
                >
                  {t("Close")}
                </button>
              </>
            );
          }}
          t={t}
        />
      )}
      <div
        className={`notifications__popup-overlay ${HandbookPopupFlag ? "notifications__popup-overlay-handbook" : ""
          }`}
      >
        <VidoeLightBox
          handleVideoBox={handleVideoBox}
          VideoLightBoxOpenState={videoLightBoxOpen}
        // toggleNotificationPopUp={props.toggleNotificationPopUp}
        />

        <ImageLightBox
          isOpen={imageLightBoxOpen ? true : false}
          handleImageBox={handleImageBox}
          ImageLightBoxOpenState={imageLightBoxOpen}
          downloadImage={() =>
            checkDeviceToOpenOrDownload(imageLightBoxOpen.obj, "download")
          }
        />
        <div className="notification__popup" ref={notification_popup_ref}>
          <label
            className="notification__popup-close"
            onClick={() => {
              if (
                document.querySelector(
                  ".ReactModal__Content.ReactModal__Content--after-open"
                )
              ) {
                // document
                //   .querySelector(
                //     ".ReactModal__Content.ReactModal__Content--after-open"
                //   )
                //   .setAttribute("style", "overflow:auto");
                document.querySelectorAll(".ReactModal__Content.ReactModal__Content--after-open").forEach(el=>{
                  el.setAttribute("style", "overflow: auto");    
                })
              }
              props.toggleNotificationPopUp("no");
            }}
          >
            &nbsp;
          </label>
          <div className="notification__popup-top">
            <h3 className="notification__popup-heading">
              {/* {notificationTitle} {t("has been shown")} */}
              {`${t("Your teacher has shared")}`} <label className="notification__popup-heading-label">{`‘${notificationTitle}’`}</label> {`${t("with you")}`}
            </h3>
            <div className="notification__popup-button-section">
              {role == "T" &&
                logUserId == notificationResourceInfo?.EnterBy && (
                  <>
                    <label
                      className="notification__popup-delete"
                      onClick={() => {
                        // deleteNotification(notificationResourceInfo.SharingId);
                        setShowConfirmDeletePopUp(true);
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt="delete icon"
                        className="notification__popup-delete-icon"
                      />
                      {t("Delete")}
                    </label>
                    {/* <label className="notification__popup-edit">
                  <img
                    src={EditIcon}
                    alt="edit icon"
                    className="notification__popup-edit-icon"
                  />
                  {t("Edit")}
                </label> */}
                  </>
                )}
            </div>
          </div>

          <div className="notification__popup-content">
            <div className="notification__popup-leftcontent">
              <div className="notification__popup-msgcontent">
                {/* <h4 className="notification__popup-message-heading">
                  {t("Message")}
                </h4>
                <p className="notification__popup-message-content">
                  {notificationTitle} {t("has been shown")}
                </p> */}
                <div className="notification__popup-book-section">
                  <h4 className="notification__popup-message-heading">
                    {t("Book")}:
                  </h4>
                  <p className="notification__popup-message-content">
 
                  {
                          notificationResourceInfo.RelatedResourcesConfig[0].Resource
                            .BookSeries.BookSeriesName[
                              notificationResourceInfo.RelatedResourcesConfig[0].Resource
                              .BookSeries.BookSeriesName[0].Lang == lang
                              ? 0
                              : 1
                          ].BookSeriesName +" "+
                          notificationResourceInfo.RelatedResourcesConfig[0].Resource
                            .Book.BookName[
                              notificationResourceInfo.RelatedResourcesConfig[0].Resource
                              .Book.BookName[0].Lang == lang
                              ? 0
                              : 1
                          ].BookName
                        }
                  </p>
                </div>
                <div className="notification__popup-book-section">
                  <h4 className="notification__popup-message-heading">
                  {t("Chapter")} / {t("Unit")}:
                  </h4>
                  <p className="notification__popup-message-content notification__popup-message-combine-content">
                  <span>
                  {notificationResourceInfo.RelatedResourcesConfig[0].RelatedStructure.ParentStructure.StructureLang[
                                    notificationResourceInfo.RelatedResourcesConfig[0].RelatedStructure.ParentStructure.StructureLang[0].Lang == lang
                                        ? 0
                                        : 1
                                    ].StructureTitle}</span>
                                    <span>
                  {notificationResourceInfo.RelatedResourcesConfig[0].RelatedStructure.StructureLang[
                                    notificationResourceInfo.RelatedResourcesConfig[0].RelatedStructure.StructureLang[0].Lang == lang
                                        ? 0
                                        : 1
                                    ].StructureTitle}
                                    </span>
                  
                  
                  </p>
                </div>
              </div>
              <div className="notification__popup-rescontent">
                <h4 className="notification__popup-message-heading">
                  {t("Resources")}
                </h4>
                <p
                  className="notification__popup-message-content content-cursor notification__popup-resource-top notification__popup-resource-area"
                  onClick={(e) => {
                    checkDeviceToOpenOrDownload(
                      notificationResourceInfo.RelatedResourcesConfig[0]
                        .Resource,
                      notificationResourceInfo.RelatedResourcesConfig[0]
                        .StudyGroup.StudyGroupName[[0].Lang == lang ? 0 : 1]
                        .StudyGroupName,
                      notificationResourceInfo.RelatedResourcesConfig[0]
                        .StudyGroupId,
                        notificationResourceInfo.RelatedResourcesConfig[0]
                        .StructureId,  
                      "open"
                    );
                  }}
                >
                  {/* {props.popUpType == "handbook" ? (
                  <img
                    alt="logo Image"
                    className="main__section-unit-grid-item-image notification__popup-icon"
                    src={DefaultIcon}
                  />
                ) : ( */}
                <div className="notification__popup-main-icon-content">
                  <img
                    alt="logo Image"
                    className="main__section-unit-grid-item-image notification__popup-icon notification__popup-main-icon"
                    src={`${notificationResourceInfo.RelatedResourcesConfig[0]
                        .Resource.Thumbnail != "" &&
                        notificationResourceInfo.RelatedResourcesConfig[0]
                          .Resource.Thumbnail != null
                        ? window.location.protocol != "http:"
                          ? "https:" +
                          notificationResourceInfo.RelatedResourcesConfig[0]
                            .Resource.Thumbnail.FilePath
                          : "http:" +
                          notificationResourceInfo.RelatedResourcesConfig[0]
                            .Resource.Thumbnail.FilePath
                        : ResourceIconMapping(
                          notificationResourceInfo.RelatedResourcesConfig[0]
                            .Resource.ResourceType,
                          "big",
                          notificationResourceInfo.RelatedResourcesConfig[0]
                            .Resource.Linkage
                        )
                      }`}
                      // onClick={(e) => {
                      //   checkDeviceToOpenOrDownload(
                      //     notificationResourceInfo.RelatedResourcesConfig[0].Resource,
                      //     notificationResourceInfo.RelatedResourcesConfig[0].StudyGroup
                      //   .StudyGroupName[[0].Lang == lang ? 0 : 1].StudyGroupName,
                      //   notificationResourceInfo.RelatedResourcesConfig[0].StudyGroupId,
                      //       "open"
                      //   );
                      // }}
                  />
                  </div>
                  {/* )} */}
                  <span className="notification__popup-resource-border"></span>
                  <div className="notification__popup-resources-title"><label className="notification__popup-resource-title">{notificationTitle}</label></div>
                </p>
                {accessLoading && <Loading />}
              </div>
            </div>
            <div className="notification__popup-rightcontent">
              {/* <h4 className="notification__popup-message-heading">{t("To")}</h4> */}
              <h4 className="notification__popup-message-heading">
                {/* {t("Class Assigned")} */}
                {t("Class")}
              </h4>
              <p className="notification__popup-message-content">
                {props.popUpType == "handbook11"
                  ? selectClass
                  : notificationResourceInfo.RelatedResourcesConfig[0]
                    .StudyGroup.StudyGroupName[[0].Lang == lang ? 0 : 1]
                    .StudyGroupName}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showConfirmDeletePopUp && (
        <NotificationPopUpConfirmation
          showConfirmDeletePopUp={showConfirmDeletePopUp}
          setShowConfirmDeletePopUp={setShowConfirmDeletePopUp}
          deleteNotification={deleteNotification}
          notificationResourceInfo={notificationResourceInfo}
          t={t}
        />
      )}
    </>
  );
};

export default NotificationPopUp;