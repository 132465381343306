
//Main
import React, { useEffect, useRef, useState, Linking } from "react";
import moment from "moment";
import environment from "../../Environment";
import { fetchQuery, graphql } from "relay-runtime";
import { Switch, Route, Redirect, matchPath } from "react-router-dom";
import banner1 from "../../assets/img/pec/all-images/banner-pec-default-desktop.jpg";
// import default_bg from "../../assets/img/pec/all-images/default_bg_banner.png";
import default_bg from "../../assets/img/pec/all-images/banner-pec-default-desktop-updated.png";
import default_dummy_img from "../../assets/img/pec/all-images/cover-default.png";
import likeIcon from "../../assets/img/pec/all-images/icon-heart-white.png";
import pullDownIcon from "../../assets/img/pec/all-images/title-pull-down-arrow.svg"
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import {
  dashboard_book_favorites,
  dashboard_book_level_favorites,
  mylevel_tab
} from "../../Actions";
//css
import "./index.scss";
import { Select } from 'antd';
import Footer from "../../Components/Footer/PEC/Footer";
import NavBar from "../../Components/NavBar/Dashboard/PEC/NavBar";
import Loading from "../../Components/Loading/PEC/Loading";
import {SCHOOL_BOOK_LIST_QUERY} from "../../constants/GraphQLQuery"
import { DeviceChecking } from "../../Services/Common/device_checking";
import HandleSSOtoOtherPlatform from "../../Services/Common/HandleSSOtoOtherPlatform";
import DashboardStudyGroup from "../../Services/api/GraphQL/Query/DashboardStudyGroup";
import UpdateUserPreferencesMutation from "../../Services/api/GraphQL/Mutation/UpdateUserPreferencesMutation";
import FavouritePage from "./FavouritePage/FavouritePage.js";
import FavouritePageStudent from "./FavouritePage/FavouritePageStudent.js";
import GCModal from "../../Components/Dashboard/GoogleCodeModal";
import { config } from "../../Config/config";
import DOMPurify from 'dompurify';
import LogoSelectCard from "./components/LogoSelectCard";
import { processImageUrl } from "../../utils/ImageProcessUtils";
import useInAppBrowser from "../../hooks/useInAppBrowser";
import useGATracking from "../../hooks/useGATracking";

function DashboardPage(props) {
  const { t } = props;
  const { Option } = Select;
  const selectOptionsSDL = useSelector(
    (state) => state.EntryOfResourceReducer.selectOptions
  );
  //console
  const isMobile = (window.innerHeight > window.innerWidth) ? window.innerWidth < 600 : window.innerHeight < 600;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.UserReducer.user);
  const role = userData?.UserType;
  const lang = useSelector((state) => state.SystemReducer.lang);
  const selectedSchoolYear = useSelector(
    (state) => state.SystemReducer.selectedSchoolYear
  );
  const {sendGaEvent} = useGATracking();
  const [backBookSeriesId, setBackBookSeriesId] = useState(
    selectOptionsSDL.selectedBookSeriesId
      ? selectOptionsSDL.selectedBookSeriesId
      : ""
  );
  // notification pop up states starts here
  const [notificationPopupOverlay, setNotificationPopupOverlay] =
    useState(false);
  // notification pop up states ends here
  const [loadData, setLoadData] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [activeMenuName, setActiveMenuName] = useState("");
  const [activeUrlMenu, setActiveUrlMenu] = useState(default_bg);
  // const [favourites, setFavourites] = useState("add");
  const [addToFavourite, setAddToFavourite] = useState([]);
  const [favBooksList, setfavBooksList] = useState([]);
  const [assessfavIds, setassessfavIds] = useState({});
  const [myassessFav, setmyassessFav] = useState([]);
  //const UserType = userData?.UserType;
  const [askForInstall, setaskForInstall] = useState(false);
  const [askForInstallToolData, setaskForInstallToolData] = useState(null);
  //Loaders 
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [loadBookNotification, setLoadBookNotification] = useState(false);
  const [levelLoading, setlevelLoading] = useState(false);
  //Loaders End
  const [studentClassId, setStudentClassId] = useState("");
  const [updateSchoolYear, setupdateSchoolYear] = useState(0);

  const [clickable, setClickable] = useState(false);

  // get the book series data starts here
  const [bookSeriesData, setbookSeriesData] = useState([]);
  const [bookData, setbookData] = useState([]);
  const [FilterBookSeriesIds, setFilterBookSeriesIds] = useState([]);
  //const [adoptedBookseries, setadoptedBookSeries] = useState([]);
  //const [bookSeriesLoader, setbookSeriesLoader] = useState(true);
  const [studyGroupList, setstudyGroupList] = useState([]);
  //const [allStudyGroupList, setAllStudyGroupList] = useState([]);
  const [nonfilterstudyGroupList, setnonfilterstudyGroupList] = useState([]);

  const [favBookLevel, setfavBookLevel] = useState([]);
  const [selectedBookId, setSelectedBookId] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {systemBrowserOpenLink} = useInAppBrowser();
  const onBookSelect = (bookSeriesId) => {
    console.log("isDropdownOpen", isDropdownOpen);
    setIsDropdownOpen(true);
    const bookSeriesName = bookSeriesData.find(book => book.BookSeriesId === bookSeriesId).BookSeriesName[0].BookSeriesName;
    setSelectedBookId(bookSeriesId);
    setassessfavIds({});
    setfavBookLevel([]);
    setLoadData(true);
    setbookData([]);
    setActiveMenu(bookSeriesId);
    setActiveMenuName(bookSeriesName);
    setLoadBookNotification(true);
    showBooks(bookSeriesId);
    setBookSeriesStudyLists(bookSeriesId);
    setIsDropdownOpen(false);
    console.log("END isDropdownOpen", isDropdownOpen);
  }
  useEffect(() => {
    if (!userData) {
      return;
    }
  }, []);

  useEffect(() => {
    if (!userData) {
      return;
    }
    if (userData.UserType == "T") {
      const updatedFav = [...new Set(myassessFav)];
      const updatedFavIds = { ...assessfavIds };
      updatedFav.forEach((id) => {
        updatedFavIds[id] = true;
      });
      setassessfavIds((prev) => ({ ...prev, ...updatedFavIds }));
    }
  }, [myassessFav]);

  useEffect(() => {
    if (role == "T") {
      setassessfavIds({});
      setfavBookLevel([]);
      setBackBookSeriesId("");
      myBookData(selectedSchoolYear.SchoolYearId);
    }

    //upstudygroup();
  }, [selectedSchoolYear]);

  // get the book series data ends here
  useEffect(() => {
    if (role == "S") {
      const variables = {
        UserId: userData.UserId,
        SchoolYearId: selectedSchoolYear.SchoolYearId,
        FilterOnboarded: false,
      };
      DashboardStudyGroup(variables, role).then((data) => {
        const {
          user: { BelongsToStudyGroup = [{}] },
        } = data || {};

        let bookseries = [];
        BelongsToStudyGroup.map((obj, i) => {
          if (
            bookseries
              .map((b) => b.BookSeriesId)
              .indexOf(obj.StudyGroup.BookSeries.BookSeriesId) == -1
          ) {
            bookseries.push(obj.StudyGroup.BookSeries);
          }
        });
        let filterList = bookseries.filter(
          (o) => o.IsB2CBookSeries == false && o.IngestionId != null
        );
        if (backBookSeriesId != "") {
          let defaultGroup = BelongsToStudyGroup.filter((obj) => {
            return (
              (obj.StudyGroup.BookSeries.IngestionId != null ||
                obj.StudyGroup.BookSeries.IngestionId != "") && obj.StudyGroup.BookSeries.IsB2CBookSeries != true &&
              obj.StudyGroup.BookSeries.BookSeriesId == backBookSeriesId
            );
          });
          if (defaultGroup.length > 0) {
            setStudentClassId(defaultGroup[0].StudyGroupId);
          }
        } else {
          let defaultGroup = BelongsToStudyGroup.filter((obj) => {
            return (
              (obj.StudyGroup.BookSeries.IngestionId != null ||
                obj.StudyGroup.BookSeries.IngestionId != "" ) && obj.StudyGroup.BookSeries.IsB2CBookSeries != true &&
              obj.StudyGroup.BookSeries.BookSeriesId == activeMenu
            );
          });
          if (activeMenu != "" && defaultGroup.length > 0) {
            setStudentClassId(defaultGroup[0].StudyGroupId);
          }
        }

        // if (defaultGroup.length == 0) {
        //   return props.history.push("/");
        // }

        setFilterBookSeriesIds(filterList);
        if (activeMenu == "") {
          myBookDataStudent(selectedSchoolYear.SchoolYearId, filterList);
        }
      });
    }
  }, [selectedSchoolYear]);
  
  const trcSdlRedirection = () => {
    console.log('favBooksList',favBooksList)
    if(favBooksList.length>0){
      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: favBooksList[0].BookId,
          selectedBookName: favBooksList[0].BookName[
            favBooksList[0].BookName[0].Lang == lang ? 0 : 1
          ].BookName,
          selectedBookSeriesName: activeMenuName,
          selectedBookSeriesId: activeMenu,
          selectedClassLevelId: "",
          selectedClassLevelName: "",
        },
      });
      props.history.push("/s/studygroup/" + activeMenu + "/rcenter?page=resource");
    } else {
      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: bookData[0].BookId,
          selectedBookName: bookData[0].BookName[
            bookData[0].BookName[0].Lang == lang ? 0 : 1
          ].BookName,
          selectedBookSeriesName: activeMenuName,
          selectedBookSeriesId: activeMenu,
          selectedClassLevelId: "",
          selectedClassLevelName: "",
        },
      });
      props.history.push("/s/studygroup/" + activeMenu + "/rcenter?page=resource");
    }
  }

  // get the class data starts here (studygrouplist)
  useEffect(() => {
    var bannerPath;
    const bannerPath1 = bookSeriesData.filter((el, i) => {
      if (el.BookSeriesId == activeMenu) {
        return el;
      }
    });
    bannerPath = bannerPath1[0]?.BookSeriesBGImage?.FilePath
      ? `https:${bannerPath1[0]?.BookSeriesBGImage?.FilePath}`
      : default_bg;
    bannerPath ? setActiveUrlMenu(bannerPath) : setActiveUrlMenu(default_bg);
    // setActiveUrlMenu();
    const variables = {
      UserId: userData.UserId,
      SchoolYearId: selectedSchoolYear.SchoolYearId,
      FilterOnboarded: false,
    };

    var favStructureId = [];
    if (role == "T") {
      var preferencesObj = dashboard_book_level_favorites(userData.Preferences);
      if (preferencesObj.length > 0) {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == activeMenu &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        if (filterList.length > 0) {
          favStructureId = filterList[0].StructureId;
        }
      }
      // setmyLevelFav(favStructureId);
      var preferencesObjBtn = mylevel_tab(userData.Preferences);
      var preferencesArrBtn = [];
      if (preferencesObjBtn.length > 0) {
        preferencesArrBtn = JSON.parse(preferencesObjBtn[0].PreferenceValue);
        // setlevelFavTab(preferencesArrBtn[0].tabSelect);
      }
      // console.log("triggered")
      if (studyGroupList.length != 0) {
        return;
      }
      setlevelLoading(true);
      DashboardStudyGroup(variables, role).then((data) => {
        const { user: { School: { StudyGroups } = {} } = {} } = data || {};

        let defaultClass;
        const studyGroupList = StudyGroups.reduce((acc, current) => {
          const {
            StudyGroupId,
            StudyGroupName,
            BookSeries,
            Level,
            StudyGroupInSameLevel,
          } = current;

          defaultClass = [
            {
              StudyGroupId: "null",
              StudyGroupName: [
                {
                  StudyGroupName: "--Please Select--",
                  Lang: "en-US",
                },
                {
                  StudyGroupName: "我的班別",
                  Lang: "zh-HK",
                },
              ],
              bookseriesId: BookSeries.BookSeriesId,
              level: Level,
              BookSeriesName: BookSeries.BookSeriesName,
            },
          ];

          acc.push({
            StudyGroupId: StudyGroupId,
            StudyGroupName: StudyGroupName,
            bookseriesId: BookSeries.BookSeriesId,
            level: Level,
            BookSeriesName: BookSeries.BookSeriesName,
          });

          return acc;
        }, []);
        studyGroupList.sort(function (a, b) {
          return a.StudyGroupName[
            a.StudyGroupName[0].Lang == lang ? 0 : 1
          ].StudyGroupName.localeCompare(
            b.StudyGroupName[b.StudyGroupName[0].Lang == lang ? 0 : 1]
              .StudyGroupName
          );
        });
        const filteredArr = studyGroupList.reduce((acc, current) => {
          const x = acc.find(
            (item) => item.StudyGroupId === current.StudyGroupId
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        // getStudyGroupName(findSameBookId);
        // setAllStudyGroupList(filteredArr);
        // setstudyGroupList(filteredArr);
        setnonfilterstudyGroupList(filteredArr);
        const findSameBookId = filteredArr.filter((data) => {
          return data.bookseriesId == activeMenu; //781,activeMenu
        });
        const finalList = [...findSameBookId];

        setstudyGroupList(finalList);
        //myBookData(selectedSchoolYear.SchoolYearId);
        // if (favStructureId.length > 0) {
        //   var favlevelup = finalList.filter((obj) => {
        //     return favStructureId.includes(obj.StudyGroupId);
        //   });
        //   setfavstudyGroupList(favlevelup);
        // }

        setlevelLoading(false);
      });
    }
  }, [activeMenu]);
  // get the class data ends here

  // function to set the studygroupdata on clicking the book series logo start here
  const setBookSeriesStudyLists = (bookSeriesId) => {
    const findSameBookId = nonfilterstudyGroupList.filter((data) => {
      return data.bookseriesId == bookSeriesId; //781,activeMenu
    });

    const finalList = [...findSameBookId];

    // getStudyGroupName(findSameBookId);

    //setAllStudyGroupList(finalList);
    setstudyGroupList(finalList);
    setLoadData(false);
    // setTimeout(() => {
      setLoadBookNotification(false);
    // }, 1000);
  };

  const myBookData = async (SchoolYear) => {
    setLoadingLogo(true);
    var values = {
      SchoolYearId: SchoolYear,
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
    };
    console.log("HERE IS values:", values);
    await fetchQuery(
      environment,
      SCHOOL_BOOK_LIST_QUERY,
      values
    ).then((data) => {
      console.log("HERE IS data:", data);
      var listArrayTemp = [];
      data.user.School.SchoolAdoptedBookSeries.map((obj, index) => {
        if (obj.IngestionId == null || obj.IngestionId == "" || obj.IsB2CBookSeries == true) return;
        listArrayTemp.push(obj);
      });
      console.log("HERE IS listArrayTemp:", listArrayTemp);
      listArrayTemp.sort(function (a, b) {
        return a.DisplaySequence - b.DisplaySequence;
      });
      var listArray = [...listArrayTemp];
      if (role == "S") {
        if (FilterBookSeriesIds.length > 0) {
          let filterBooks = listArrayTemp.filter(
            (o) =>
              FilterBookSeriesIds.map((z) => z.BookSeriesId).indexOf(
                o.BookSeriesId
              ) != -1
          );
          listArray = [...filterBooks];
        }
      }
      console.log("HERE IS listArray:", listArray);
      if (listArray.length == 0) {
        //setbookSeriesLoader(false);
        setLoadingLogo(false);
      } else {
        setbookSeriesData(listArray);
        setSelectedBookId(listArray[0]?.BookSeriesId);
        if (backBookSeriesId != "") {
          listArray = listArrayTemp.find((o) => o.BookSeriesId === backBookSeriesId) ?
              listArrayTemp.filter((o) => o.BookSeriesId === backBookSeriesId) : listArray;
        }
        setActiveMenu(listArray[0].BookSeriesId);
        setActiveMenuName(listArray[0].BookSeriesName[0].BookSeriesName);
        setbookData(listArray[0].AdoptedBooks);
        //setadoptedBookSeries(listArray);

        if (role == "T") {
          //setting up other resources
          var favStructureId = [];
          var apreferencesObj = dashboard_book_favorites(userData.Preferences);
          if (apreferencesObj.length > 0) {
            var preferencesArr = JSON.parse(apreferencesObj[0].PreferenceValue);
            let filterList = preferencesArr.filter(
              (o) =>
                o.BSId == listArray[0].BookSeriesId &&
                o.SchoolYear == selectedSchoolYear.SchoolYearId
            );
            if (filterList.length > 0) {
              favStructureId = filterList[0].StructureId;
            }
          }

          var favBooks = listArray[0].AdoptedBooks;
          var favoritedData = favBooks.filter((obj) => {
            return favStructureId.includes(obj.BookId);
          });
          if (userData.UserType == "T") {
            setmyassessFav(favStructureId);
          }
          setfavBooksList(favoritedData);

          var favbooklevelvar = [];
          var preferencesObjB = dashboard_book_level_favorites(
            userData.Preferences
          );
          if (preferencesObjB.length > 0) {
            // alert('inside')
            var preferencesArrB = JSON.parse(
              preferencesObjB[0].PreferenceValue
            );
            favoritedData.forEach((element) => {
              let filterList = preferencesArrB.filter(
                (o) =>
                  o.BSId == element.BookId &&
                  o.SchoolYear == selectedSchoolYear.SchoolYearId
              );
              if (filterList.length > 0) {
                var obj = {
                  BSId: element.BookId,
                  StructureId: filterList[0].StructureId,
                  SchoolYear: filterList[0].SchoolYear,
                };
                favbooklevelvar.push(obj);
              }
            });
            setfavBookLevel(favbooklevelvar);
            // console.log('favBookLevel',favbooklevelvar)
          }
          setfavBookLevel(favbooklevelvar);
          // console.log('favBookLevel',favbooklevelvar)
        }

        //End
        //setbookSeriesLoader(false);
        setLoadingLogo(false);
        // setbookSerisLoading(false);
      }
    });
  };

  const myBookDataStudent = (SchoolYear, filterIds) => {
    setLoadingLogo(true);
    var values = {
      SchoolYearId: SchoolYear,
      UserId: JSON.parse(localStorage.getItem("userData")).UserId,
    };
    fetchQuery(
      environment,
      SCHOOL_BOOK_LIST_QUERY,
      values
    ).then((data) => {
      var listArrayTemp = [];
      data.user.School.SchoolAdoptedBookSeries.map((obj, index) => {
        if (obj.IngestionId == null || obj.IngestionId == "") return;
        listArrayTemp.push(obj);
      });
      listArrayTemp.sort(function (a, b) {
        return a.DisplaySequence - b.DisplaySequence;
      });

      var listArray = [...listArrayTemp];

      if (filterIds.length > 0) {
        let filterBooks = listArrayTemp.filter(
          (o) =>
            filterIds.map((z) => z.BookSeriesId).indexOf(o.BookSeriesId) != -1
        );
        listArray = [...filterBooks];
      }

      setbookSeriesData(listArray);
      setSelectedBookId(listArray[0]?.BookSeriesId);
      if (backBookSeriesId != "") {
        listArray = listArrayTemp.filter(
          (o) => o.BookSeriesId == backBookSeriesId
        );
      }

      if (listArray.length == 0) {
        //setbookSeriesLoader(false);
        setLoadingLogo(false);
      } else {
        setActiveMenu(listArray[0].BookSeriesId);
        setActiveMenuName(listArray[0].BookSeriesName[0].BookSeriesName);
        setbookData(listArray[0].AdoptedBooks);
        //setadoptedBookSeries(listArray);

        //End
        //setbookSeriesLoader(false);
        setLoadingLogo(false);
        // setbookSerisLoading(false);
      }
    });
  };

  const updateAssess = (e, structureIdArg, val, bookSeriesNamearg) => {
    setassessfavIds((prev) => ({
      ...prev,
      [structureIdArg]: val === "yes" ? true : false,
    }));
    e.stopPropagation();
    setClickable(1);
    //heartClickassesment();
    // var val = "";
    var bookSeriesIdArg = activeMenu;
    var respreferencesArr = [];
    var latestStructureId = [];
    var preferencesObj = dashboard_book_favorites(userData.Preferences);
    if (preferencesObj.length > 0) {
      if (val == "yes") {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );

        if (filterList.length > 0) {
          let updateStructureList = [];
          filterList[0].StructureId.forEach(function (id, index) {
            updateStructureList.push(id);
          });
          updateStructureList.push(structureIdArg);
          var updateObj = {
            BSId: bookSeriesIdArg,
            StructureId: updateStructureList,
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
          respreferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
          const indx = respreferencesArr.findIndex(
            (v) =>
              v.BSId == bookSeriesIdArg &&
              v.SchoolYear == selectedSchoolYear.SchoolYearId
          );

          respreferencesArr.splice(indx, indx >= 0 ? 1 : 0);
          respreferencesArr.push(updateObj);
          latestStructureId = updateStructureList;
        } else {
          respreferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
          let structureVal = [];
          structureVal.push(structureIdArg);
          var obj = {
            BSId: bookSeriesIdArg,
            StructureId: structureVal,
            SchoolYear: selectedSchoolYear.SchoolYearId,
          };
          respreferencesArr.push(obj);
          latestStructureId = structureVal;
        }
      } else {
        var preferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesIdArg &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );

        //Removing From Structure
        let updateStructureList = [];
        filterList[0].StructureId.forEach(function (id, index) {
          updateStructureList.push(id);
        });
        const indx = updateStructureList.findIndex((v) => v == structureIdArg);
        updateStructureList.splice(indx, indx >= 0 ? 1 : 0);
        //Removing From BookSeries
        respreferencesArr = JSON.parse(preferencesObj[0].PreferenceValue);
        const bindx = respreferencesArr.findIndex(
          (v) =>
            v.BSId == bookSeriesIdArg &&
            v.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        respreferencesArr.splice(bindx, bindx >= 0 ? 1 : 0);
        var updateObj = {
          BSId: bookSeriesIdArg,
          StructureId: updateStructureList,
          SchoolYear: selectedSchoolYear.SchoolYearId,
        };
        respreferencesArr.push(updateObj);
        latestStructureId = updateStructureList;
      }
    } else {
      let structureVal = [];
      structureVal.push(structureIdArg);
      var obj = {
        BSId: bookSeriesIdArg,
        StructureId: structureVal,
        SchoolYear: selectedSchoolYear.SchoolYearId,
      };
      respreferencesArr.push(obj);
      latestStructureId = structureVal;
    }

    // respreferencesArr = []
    // var obj = {
    //   BSId: bookSeriesIdArg,
    //   StructureId: [],
    //   SchoolYear: selectedSchoolYear.SchoolYearId,
    // };
    // respreferencesArr.push(obj);
    //   latestStructureId = [];

    var data = {
      PreferenceName: "DASHBOARD_BOOK_FAVORITES",
      PreferenceValue: JSON.stringify(respreferencesArr),
    };
    const callback = (result) => {
      userData.Preferences = result.modifyUserPreference.Preferences;
      dispatch({
        type: "SET_USER_PREFERENCES",
        user_preferences: result.modifyUserPreference.Preferences,
      });

      localStorage.setItem("userData", JSON.stringify(userData));

      setClickable(0);
    };
    setAddToFavourite(latestStructureId);

    var favoritedData = bookData.filter((obj) => {
      return latestStructureId.includes(obj.BookId);
    });
    setfavBooksList(favoritedData);

    // add to favourite while coming from sdl page and no book is favourited starts here
    var favbooklevelvar = [];
      var preferencesObjB = dashboard_book_level_favorites(
        userData.Preferences
      );

      if (preferencesObjB.length > 0) {
        var preferencesArrB = JSON.parse(preferencesObjB[0].PreferenceValue);
        favoritedData.forEach((element) => {
          let filterList = preferencesArrB.filter(
            (o) =>
              o.BSId == element.BookId &&
              o.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          if (filterList.length > 0) {
            var obj = {
              BSId: element.BookId,
              StructureId: filterList[0].StructureId,
              SchoolYear: filterList[0].SchoolYear,
            };
            favbooklevelvar.push(obj);
          }
        });
        setfavBookLevel(favbooklevelvar);
        // console.log('favBookLevel',favbooklevelvar)
      }
      // add to favourite while coming from sdl page and no book is favourited ends here

    // setmyassessFav(latestStructureId);
    UpdateUserPreferencesMutation(data, callback);

    // var favoritedData = bookData.filter((obj) => {
    //   return latestStructureId.includes(obj.BookId);
    // });
    // setfavBooksList(favoritedData);
    // setmyassessFav(latestStructureId);
    // var favoritedData = booksList.filter((obj) => {
    //   return latestStructureId.includes(obj.BookId);
    // });
    // setbooksList(favoritedData);
  };

  // function to set the studygroupdata on clicking the book series logo ends here

  const toggleClickable = (val) => {
    if (val == 1) {
      setClickable(true);
    } else {
      setClickable(false);
    }
  };

  const onClickResource = (
    bookseriesId,
    action,
    bookId = "",
    bookName = "",
    event
  ) => {
    try {
      event.preventDefault();
    } catch (e) {}
    if (action == "history") {
      dispatch({
        type: "SET_SELECTED_BOOK",
        selectOptions: {
          selectedBookId: bookId,
          selectedBookName: bookName,
          selectedBookSeriesName: activeMenuName,
          selectedBookSeriesId: bookseriesId,
          selectedClassLevelId: "",
          selectedClassLevelName: "",
        },
      });
      props.history.push("/s/studygroup/" + bookseriesId + "/rcenter");
    }
    return false;
  };

  //handle pop up here, if needed
  const askForInstallPopUp = (tool) => {
    setaskForInstallToolData(tool);
    setaskForInstall(true);
  };

  const askForInstallOnClick = () => {
    let device = DeviceChecking();
    // const { askForInstallToolData } = this.state;
    // const lang = localStorage.getItem("i18nextLng");
    if (device == "ANDROID_APP") {
      console.log(
        "[App] App is not installed, redirct to play store url path:" +
          config.playStore +
          askForInstallToolData.toolData.AndroidAppId +
          (lang == "en-US" ? config.playStoreEng : config.playStoreChi)
      );
      systemBrowserOpenLink(
        config.playStore +
          askForInstallToolData.toolData.AndroidAppId +
          (lang == "en-US" ? config.playStoreEng : config.playStoreChi)
      );
    }
    if (device == "IOS_APP") {
      console.log(
        "[App] App is not installed, redirct to App store url:",
        encodeURI(
          config.AppStore +
            askForInstallToolData.toolData.IOSAppId +
            (lang == "en-US" ? config.AppStoreEng : config.AppStoreChi)
        )
      );
      setaskForInstall(false);
      systemBrowserOpenLink(
        encodeURI(
          config.AppStore +
            askForInstallToolData.toolData.IOSAppId +
            (lang == "en-US" ? config.AppStoreEng : config.AppStoreChi)
        )
      );
    }
  };


  const onClickTool = (bookseriesId, action, bookId = "", bookName = "") => {
    if (window.cordova && isDropdownOpen) {
      console.log("DROPDOWN OPEN");
      return;
    }
    // return askForInstallPopUp(tool);
    if (bookseriesId == "") {
      return false;
    }
    let filterList = bookSeriesData.filter(
      (o) => o.BookSeriesId == bookseriesId
    );
    //var usefulToolsObj = filterList[0].UsefulTools;
    let pre_tools = JSON.parse(JSON.stringify(filterList[0].UsefulTools));
    pre_tools = pre_tools.sort((a, b) => a.DisplaySequence - b.DisplaySequence);

    var items = [];
    pre_tools.map((obj, i) => {
      if (
        ((obj.WebURL == "" || obj.WebURL == null) && // no web data
          !window.cordova) || //not show in web verison
        (obj.WebSupported == "N" &&
          obj.TabletSupported == "N" &&
          obj.MobileSupported == "N" &&
          obj.PCSupported == "Y" &&
          window.cordova) || //only show in PC
        (obj.TabletSupported == "N" &&
          obj.MobileSupported == "N" &&
          (obj.WebSupported == "Y" || obj.PCSupported == "Y") &&
          window.cordova)
      )
        return;
      if (obj.ApplicableRole == "T") {
        if (userData.UserType == "T") {
          items.push({
            order: 1,
            type: obj.ToolsType,
            icon: obj.ToolsIconFile.FilePath,
            showOpenIcon: true,
            label: obj.UsefulToolsLangs,
            toolData: obj,
          });
        }
      } else {
        items.push({
          order: 1,
          type: obj.ToolsType,
          icon: obj.ToolsIconFile.FilePath,
          showOpenIcon: true,
          label: obj.UsefulToolsLangs,
          toolData: obj,
        });
      }
    });
    items = items.sort((a, b) => a.order - b.order);

    if (action == "check") {
      if (items.length > 0) {
        return true;
      }
      return false;
    }
    var toolList = items.filter((o) => o.type == "web");
    if (action == "check") {
      if (toolList.length > 0) {
        return true;
      }
      return false;
    }

    var tool = toolList[0];
    const device = DeviceChecking();
    let webLink, link;

    //alert(tool?.type)
    switch (tool?.type) {
      case "EbookLinkage":
      case "ebook":
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
          return HandleSSOtoOtherPlatform("WEB", {
            platform: "ebook",
            url: webLink,
          });
        }
        console.log("tool: ", tool);
        if (device == "IOS_APP" || device == "ANDROID_APP") {
          if (device == "ANDROID_APP") {
            if (
              tool.toolData.AndroidAppId !== null &&
              tool.toolData.AndroidAppURL !== null
            ) {
              link =
                lang == "en-US"
                  ? tool.toolData.AndroidAppURL
                  : tool.toolData.AndroidAppURLChi;
              appAvailability.check(
                tool.toolData.AndroidAppId,
                () => {
                  //success callback
                  HandleSSOtoOtherPlatform("ANDROID", {
                    platform: "ebook",
                    url: link,
                  });
                  // openAppPath(link);
                },
                () => {
                  // Error callback no app installed
                  askForInstallPopUp(tool);
                }
              );
            } else {
              // openAppPath(link);
              webLink =
                lang == "en-US"
                  ? tool.toolData.WebURL
                  : tool.toolData.WebURLChi;
              HandleSSOtoOtherPlatform("WEB", {
                platform: "ebook",
                url: webLink,
                isDefaultBrowser: true,
              });
            }
          }
          if (device == "IOS_APP") {
            link =
              lang == "en-US"
                ? tool.toolData.IOSAppURL
                : tool.toolData.IOSAppURLChi;

            if (
              tool.toolData.IOSAppId !== null &&
              tool.toolData.IOSAppName !== null &&
              tool.toolData.IOSAppURL !== null
            ) {
              var getIdName = tool.toolData.IOSAppURL.slice(
                0,
                tool.toolData.IOSAppURL.indexOf("://") + 3
              );
              appAvailability.check(
                getIdName,
                () => {
                  //success callback
                  HandleSSOtoOtherPlatform("IOS", {
                    platform: "ebook",
                    url: link,
                  });
                  // openAppPath(link);
                },
                () => {
                  // Error callback no app installed
                  // that.setState({ askForInstall: true });
                  askForInstallPopUp(tool);
                }
              );
            } else {
              webLink =
                lang == "en-US"
                  ? tool.toolData.WebURL
                  : tool.toolData.WebURLChi;
              HandleSSOtoOtherPlatform("WEB", {
                platform: "ebook",
                url: webLink,
                isDefaultBrowser: true,
              });
              // openAppPath(link);
            }
          }
        }
        break;
      case "MasterWebLinkage":
        webLink =
          lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
        if (device == "WEB") {
          return HandleSSOtoOtherPlatform("WEB", {
            platform: "web",
            url: webLink,
          });
        }
        if (device == "IOS_APP" || device == "ANDROID_APP") {
          return HandleSSOtoOtherPlatform("WEB", {
            platform: "web",
            url: webLink,
            isDefaultBrowser: true,
          });
        }
        break;
      case "DwbLinkage":
        webLink =
          lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
        if (device == "WEB") {
          return HandleSSOtoOtherPlatform("WEB", {
            platform: "DWB",
            url: webLink,
          });
        }
        if (device == "IOS_APP" || device == "ANDROID_APP") {
          return HandleSSOtoOtherPlatform("WEB", {
            platform: "DWB",
            url: webLink,
            isDefaultBrowser: true,
          });
        }
        break;

      default:
        if (device == "IOS_APP") {
          if (
            tool.toolData.IOSAppId != "" &&
            tool.toolData.IOSAppId != null &&
            tool.toolData.IOSAppName != "" &&
            tool.toolData.IOSAppName != null &&
            tool.toolData.IOSAppURL != "" &&
            tool.toolData.IOSAppURL != null
          ) {
            var getIdName = tool.toolData.IOSAppURL.slice(
              0,
              tool.toolData.IOSAppURL.indexOf("://") + 3
            );

            appAvailability.check(
              getIdName, // URI Scheme
              () => {
                // Success callback
                link =
                  lang == "en-US"
                    ? tool.toolData.IOSAppURL
                    : tool.toolData.IOSAppURLChi;
                return HandleSSOtoOtherPlatform("IOS", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                // Error callback

                askForInstallPopUp(tool);
              }
            );
          } else {
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "ANDROID_APP") {
          if (
            tool.toolData.AndroidAppId != "" &&
            tool.toolData.AndroidAppId != null &&
            tool.toolData.AndroidAppURL != "" &&
            tool.toolData.AndroidAppURL != null
          ) {
            //have android data

            appAvailability.check(
              tool.toolData.AndroidAppId, // Package Name
              (info) => {
                // Success callback
                // Info parameter is available only for android
                link =
                  lang == "en-US"
                    ? tool.toolData.AndroidAppURL
                    : tool.toolData.AndroidAppURLChi;

                return HandleSSOtoOtherPlatform("ANDROID", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                askForInstallPopUp(tool);
              }
            );
          } else {
            //no android data
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool?.toolData.WebURL : tool?.toolData.WebURLChi;

          return HandleSSOtoOtherPlatform("WEB", {
            platform: tool?.type,
            url: webLink,
          });
        }
        break;
    }
  };

  // function to open the link in new tab starts here
  const onBtnClickOpenLink = async (obj) => {
    var items = [];
    if (
      ((obj.WebURL == "" || obj.WebURL == null) && // no web data
        !window.cordova) || //not show in web verison
      (obj.WebSupported == "N" &&
        obj.TabletSupported == "N" &&
        obj.MobileSupported == "N" &&
        obj.PCSupported == "Y" &&
        window.cordova) || //only show in PC
      (obj.TabletSupported == "N" &&
        obj.MobileSupported == "N" &&
        (obj.WebSupported == "Y" || obj.PCSupported == "Y") &&
        window.cordova)
    )
      return;
    if (obj.ApplicableRole == "T") {
      if (userData.UserType == "T") {
        items.push({
          order: 1,
          type: obj.ToolsType,
          icon: obj.ToolsIconFile.FilePath,
          showOpenIcon: true,
          label: obj.UsefulToolsLangs,
          toolData: obj,
        });
      }
    } else {
      items.push({
        order: 1,
        type: obj.ToolsType,
        icon: obj.ToolsIconFile.FilePath,
        showOpenIcon: true,
        label: obj.UsefulToolsLangs,
        toolData: obj,
      });
    }

    const device = DeviceChecking();
    let webLink, link;
    var tool = items[0];
    console.log("tool: ", tool);
    switch (tool.type) {
      case "EbookLinkage":
      case "ebook":
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
          return HandleSSOtoOtherPlatform("WEB", {
            platform: "ebook",
            url: webLink,
          });
        }
        // HERE IS EBOOK DEEPLINK
          if (device == "ANDROID_APP") {
            if (tool.toolData.AndroidAppId !== null && tool.toolData.AndroidAppURL !== null) {
              link = tool.toolData.AndroidAppURL;
              HandleSSOtoOtherPlatform("ANDROID", {
                platform: "ebook",
                url: link,
              });
              await new Promise(resolve => setTimeout( ()=> {
                if (document.visibilityState === 'visible'){
                  askForInstallPopUp(tool);
                }
                resolve();
              }, 1000));
            } else {
              // openAppPath(link);
              webLink = tool.toolData.WebURL;
              HandleSSOtoOtherPlatform("WEB", {
                platform: "ebook",
                url: webLink,
                isDefaultBrowser: true,
              });
            }
          }
          if (device == "IOS_APP") {
            link =
              lang == "en-US"
                ? tool.toolData.IOSAppURL
                : tool.toolData.IOSAppURLChi;

            if (
              tool.toolData.IOSAppId !== null &&
              tool.toolData.IOSAppName !== null &&
              tool.toolData.IOSAppURL !== null
            ) {
              var getIdName = tool.toolData.IOSAppURL.slice(
                0,
                tool.toolData.IOSAppURL.indexOf("://") + 3
              );
              HandleSSOtoOtherPlatform("IOS", {
                platform: "ebook",
                url: link,
              });
              await new Promise(resolve => setTimeout( ()=> {
                if (document.visibilityState === 'visible'){
                  askForInstallPopUp(tool);
                }
                resolve();
              }, 1000));
            } else {
              webLink = tool.toolData.WebURL;
              HandleSSOtoOtherPlatform("WEB", {
                platform: "ebook",
                url: webLink,
                isDefaultBrowser: true,
              });
              // openAppPath(link);
            }
          }
        break;
      default:
        if (device == "IOS_APP") {
          if (
            tool.toolData.IOSAppId != "" &&
            tool.toolData.IOSAppId != null &&
            tool.toolData.IOSAppName != "" &&
            tool.toolData.IOSAppName != null &&
            tool.toolData.IOSAppURL != "" &&
            tool.toolData.IOSAppURL != null
          ) {
            var getIdName = tool.toolData.IOSAppURL.slice(
              0,
              tool.toolData.IOSAppURL.indexOf("://") + 3
            );

            appAvailability.check(
              getIdName, // URI Scheme
              () => {
                // Success callback
                link =
                  lang == "en-US"
                    ? tool.toolData.IOSAppURL
                    : tool.toolData.IOSAppURLChi;
                return HandleSSOtoOtherPlatform("IOS", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                // Error callback

                askForInstallPopUp(tool);
              }
            );
          } else {
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "ANDROID_APP") {
          if (
            tool.toolData.AndroidAppId != "" &&
            tool.toolData.AndroidAppId != null &&
            tool.toolData.AndroidAppURL != "" &&
            tool.toolData.AndroidAppURL != null
          ) {
            //have android data

            appAvailability.check(
              tool.toolData.AndroidAppId, // Package Name
              (info) => {
                // Success callback
                // Info parameter is available only for android
                link =
                  lang == "en-US"
                    ? tool.toolData.AndroidAppURL
                    : tool.toolData.AndroidAppURLChi;

                return HandleSSOtoOtherPlatform("ANDROID", {
                  platform: tool.type,
                  url: link,
                });
              },
              () => {
                askForInstallPopUp(tool);
              }
            );
          } else {
            //no android data
            webLink =
              lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;
            return HandleSSOtoOtherPlatform("WEB", {
              platform: tool.type,
              url: webLink,
              isDefaultBrowser: true,
            });
          }
        }
        if (device == "WEB") {
          webLink =
            lang == "en-US" ? tool.toolData.WebURL : tool.toolData.WebURLChi;

          return HandleSSOtoOtherPlatform("WEB", {
            platform: tool.type,
            url: webLink,
          });
        }
        break;
    }
  };
  // function to open the link in new tab ends here

  const showBooks = (bookSeriesId) => {
    let bookseriesArr = bookSeriesData.filter(
      (o) => o.BookSeriesId == bookSeriesId
    );
    setbookData(bookseriesArr[0].AdoptedBooks);

    if (role == "T") {
      var favStructureId = [];
      var apreferencesObj = dashboard_book_favorites(userData.Preferences);
      if (apreferencesObj.length > 0) {
        var preferencesArr = JSON.parse(apreferencesObj[0].PreferenceValue);
        let filterList = preferencesArr.filter(
          (o) =>
            o.BSId == bookSeriesId &&
            o.SchoolYear == selectedSchoolYear.SchoolYearId
        );
        if (filterList.length > 0) {
          favStructureId = filterList[0].StructureId;
        }
      }

      var favBooks = bookseriesArr[0].AdoptedBooks;
      var favoritedData = favBooks.filter((obj) => {
        return favStructureId.includes(obj.BookId);
      });
      if (userData.UserType == "T") {
        setmyassessFav(favStructureId);
      }
      setfavBooksList(favoritedData);

      var favbooklevelvar = [];
      var preferencesObjB = dashboard_book_level_favorites(
        userData.Preferences
      );

      if (preferencesObjB.length > 0) {
        var preferencesArrB = JSON.parse(preferencesObjB[0].PreferenceValue);
        favoritedData.forEach((element) => {
          let filterList = preferencesArrB.filter(
            (o) =>
              o.BSId == element.BookId &&
              o.SchoolYear == selectedSchoolYear.SchoolYearId
          );
          if (filterList.length > 0) {
            var obj = {
              BSId: element.BookId,
              StructureId: filterList[0].StructureId,
              SchoolYear: filterList[0].SchoolYear,
            };
            favbooklevelvar.push(obj);
          }
        });
        setfavBookLevel(favbooklevelvar);
        // console.log('favBookLevel',favbooklevelvar)
      }
      setfavBookLevel(favbooklevelvar);
      // console.log('favBookLevel',favbooklevelvar)
    }

    setLoadData(false);
  };
  const onChangeSelectedSchoolYear = (e) => {
    const targetYear = userData.AdoptedSchoolYear.filter(
      (y) => y.SchoolYearId == e.target.value
    )[0];

    // GetNotificationCount({
    //   UserId: JSON.parse(localStorage.getItem("userData")).UserId,
    //   SchoolYearId: e.target.value,
    // }).then((data, error) => {
    //   const {
    //     user: { NotificationCount = 0 },
    //   } = data || {};
    //   dispatch({
    //     type: "UPDATE_NOTIFICATION_COUNT",
    //     count: parseInt(NotificationCount),
    //   });
    //   setnotificationCountLocal(parseInt(NotificationCount));
    //   // setnotificationUnreadCount(parseInt(NotificationCount));
    // });
    dispatch({
      type: "SET_COSTOM_SCHOOL_YEAR",
      selectedSchoolYear: targetYear,
    });
    setupdateSchoolYear(updateSchoolYear + 1);

    // myBookData(targetYear.SchoolYearId);
  };

  const removeFavourite = (id) => {
    const removedFav = addToFavourite.filter((el) => el !== id);
    setAddToFavourite(removedFav);
  };

  if (!userData) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="be-wrapper be-fixed-sidebar">
        <div className="be-container">
          <div
            className="name-banner"
            style={{
              backgroundImage: `url(${activeUrlMenu})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top center",
            }}
          >
            <NavBar
              {...props}
              isAssessmentView={false}
              backTitle={null}
              showRigthButton={true}
              showFlag={true}
              updateSchoolYear={updateSchoolYear}
            />
            <div className="loggedin-details">
              <p className="logged-details">
                <span className="loggedin-title">{t("Welcome back")},</span>
                {/* <span className="loggedin-name">Chris Wong!</span> */}
                <span className="loggedin-name">{`${localStorage.getItem("i18nextLng") == "en-US"
                  ? userData.UserDisplayName[
                    userData.UserDisplayName[0].Lang == "en-US" ? 0 : 1
                  ].UserName
                  : userData.UserDisplayName[
                    userData.UserDisplayName[0].Lang == "zh-HK" ? 0 : 1
                  ].UserName != ""
                    ? userData.UserDisplayName[
                      userData.UserDisplayName[0].Lang == "zh-HK" ? 0 : 1
                    ].UserName
                    : userData.UserDisplayName[
                      userData.UserDisplayName[0].Lang == "en-US" ? 0 : 1
                    ].UserName
                  }`}</span>
              </p>
            </div>
          </div>
          <div
            className={`be-content card-pb bg-color site-wrapper site-wrapper-right be-content-padding ${clickable ? "not-clickable" : ""
              } ${notificationPopupOverlay ? "be-content-transform" : ""}`}
          >
            <div className="action">
            {window.cordova && (
            <Modal
              isOpen={askForInstall}
              onAfterOpen={() => {}}
              onRequestClose={() => {
                setaskForInstallToolData(null);
                setaskForInstall(false);
              }}
              style={{
                content: {
                  maxWidth: "450px",
                },
              }}
              contentLabel="As For Install"
              ariaHideApp={false}
              //shouldCloseOnOverlayClick={false}
            >
              <div className="modal-header modal-header-colored">
              </div>
              <div className="modal-body text-center">
                <div style={{ maxWidth: "330px", margin: "0 auto" }}>
                  If you're using a tablet computer, install the <b>Pearson e-bookshelf</b> app now.
                  <br/>
                  <br/>
                  <i>(Use of e-Books not recommended on mobile phones due to resolution restrictions.)</i>
                </div>
              </div>
              <div className="modal-footer text-center">
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => askForInstallOnClick()}
                >
                  {t("Install")}
                </button>
              </div>
              <button
                type="button"
                className="react-modal-close"
                title="Close"
                onClick={() => setaskForInstall(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1"
                  viewBox="0 0 24 24"
                >
                  <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
                </svg>
              </button>
            </Modal>
          )}
              <div className="whole-tab">
                <div className="tab-group">
                  {loadingLogo ? (
                    <div className="logo-image-loading">
                      <Loading type={`normal`} />
                    </div>
                  ) : (
                    <>
                      {
                        window.cordova && isMobile && bookSeriesData.length > 0 ?
                          <>
                            <Select
                              className={`tab active`}
                              bordered={false}
                              onChange={onBookSelect}
                              suffixIcon={
                                <img
                                  src={pullDownIcon}
                                  style={{ height: "8px" }}
                                />
                              }
                              value={selectedBookId}
                              style={{ minHeight: "90px"}}
                              showArrow={bookSeriesData.length > 1}
                            >
                              {
                                bookSeriesData.map(el =>
                                  <Option value={el.BookSeriesId} key={el.BookSeriesId}>
                                    <LogoSelectCard el={el} onSelectClick={onBookSelect} />
                                  </Option>)
                              }
                            </Select>
                            <div className="line"></div>
                          </> :
                          !loadingLogo &&
                          bookSeriesData.length > 0 &&
                          bookSeriesData.map((el, i) => {
                            if (el.BookSeriesImage?.FilePath)
                              return (
                                <a
                                  className={`tab ${activeMenu == el.BookSeriesId ? "active" : ""
                                    }`}
                                  href="#"
                                >
                                  <img
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setassessfavIds({});
                                      setfavBookLevel([]);
                                      setLoadData(true);
                                      setbookData([]);
                                      setActiveMenu(el.BookSeriesId);
                                      setActiveMenuName(
                                        el.BookSeriesName[0].BookSeriesName
                                      );

                                      setLoadBookNotification(true);
                                      showBooks(el.BookSeriesId);
                                      setBookSeriesStudyLists(el.BookSeriesId);
                                      // setTimeout(() => {
                                      //   setLoadData(false);
                                      // }, 1300);
                                    }}
                                    className="logo-image grey-logo-image"
                                    alt="logo2"
                                    src={DOMPurify.sanitize(`https:${el.BookSeriesImage.FilePath}`)}
                                  />
                                  <div className="line"></div>
                                </a>
                              );
                          })}
                    </>
                  )}
                </div>

                <div className="right-control">
                  <div className="right-section-menu">
                    <div className="school-years">
                      <span className="school-class-names">
                        {t("School Year")}:
                      </span>
                      <select
                          onClick={(e) => {
                            if (window.cordova && isDropdownOpen) {
                              e.preventDefault();
                            }
                          }}
                        className="year-drop-down"
                        onChange={(e) => {
                          onChangeSelectedSchoolYear(e);
                          setbookSeriesData([]);
                          setbookData([]);
                          setActiveMenu("");
                        }}
                        defaultValue={selectedSchoolYear.SchoolYearId}
                      >
                        {userData.AdoptedSchoolYear.sort(function (a, b) {
                          return (
                            new moment(b.EffectiveStartDate) -
                            new moment(a.EffectiveStartDate)
                          );
                        }).map((year, i) => {
                          return (
                            <option value={year.SchoolYearId} key={i}>
                              {year.SchoolYearName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {role == "T"
                      ? !loadingLogo &&
                      onClickTool(activeMenu, "check") &&
                      bookSeriesData.length > 0 && (
                        <button
                          className="btn btn-primary resource-btn"
                          onClick={() => {
                            if (!window.cordova) {
                              window.gtag('event', 'Dashboard - Click Book_Series external link', {
                                'Button' : 'Tearcher Resource Center',
                                'Book_Series': activeMenuName,
                                'user_Role': role 
                              });
                            } else {
                              sendGaEvent('Dashboard - Click Book_Series external link', {
                                    'Button' : 'Tearcher Resource Center',
                                    'Book_Series': activeMenuName,
                                    'user_Role': role
                                });
                            }
                            //trcSdlRedirection()
                            console.log("onClickTool Start");
                            onClickTool(activeMenu, "link")
                          }}
                        >
                          {t("Teachers' Resource Centre")}
                          <i className="icon mdi mdi-open-in-new new-open-icon"></i>
                        </button>
                      )
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            {!loadingLogo &&
              bookSeriesData.length > 0 &&
              (role == "T" ? (
                <>
                  {levelLoading ? (
                    <div className="load-spinners">
                      <Loading type={`normal`} />
                    </div>
                  ) : (
                    <FavouritePage
                      addToFavourite={addToFavourite}
                      favBookLevel={favBookLevel}
                      allData={favBooksList}
                      loadBookNotification={loadBookNotification}
                      removeFavourite={updateAssess}
                      toggleClickable={toggleClickable}
                      activeMenu={activeMenu}
                      t={t}
                      role={role}
                      setfavBookLevel={(favdata, val) => {
                        // const indx = favBookLevel.findIndex(
                        //   (v) => v.bookId == favdata.bookId
                        // );
                        // favBookLevel.splice(indx, indx >= 0 ? 1 : 0);
                        // favBookLevel.push(favdata);
                        // let favtemp = [];
                        // favtemp.push(favdata);
                        //test
                        setfavBookLevel(favdata);
                      }}
                      studyGroupList={studyGroupList}
                      onBtnClickOpenLink={onBtnClickOpenLink}
                      onClickTool={onClickTool}
                      onClickResource={onClickResource}
                      notificationPopupOverlay={notificationPopupOverlay}
                      setNotificationPopupOverlay={setNotificationPopupOverlay}
                      activeMenuName={activeMenuName}
                    />
                  )}
                  {/* {loadData ? ( */}
                  {levelLoading ? (
                    <div className="load-spinners">
                      <Loading type={`normal`} />
                    </div>
                  ) : (
                    <div className="select-books">
                      <h3 className="select-books-heading">
                        {t("Select Books")}
                      </h3>
                      {bookData.length > 0 ? (
                        <div className="book-series">
                          {bookData.map((data) => {
                            return (
                              <div
                                className="single-book-item single-book-border"
                                id="photos"
                              >
                                <a
                                  href="#"
                                  className="darken hover-darken-image"
                                  onClick={(e) =>{
                                    dispatch({
                                      type: "SET_SELECTED_TYPE",
                                      selectOptions: {
      
                                        selectedResourceName:"",
                                        selectecResourceTab:"",
                                        selectedToggle:"",
                                        showIframeOrSDL:"sdl"
      
                                      },
                                    });
                                    onClickResource(
                                      activeMenu,
                                      "history",
                                      data.BookId,
                                      data.BookName[
                                        data.BookName[0].Lang == lang ? 0 : 1
                                      ].BookName,
                                        e
                                    )
                                  }}
                                >
                                  <img
                                    className="darken book-image"
                                    src={
                                      data.BookImage
                                        ? DOMPurify.sanitize(processImageUrl(data.BookImage.FilePath))
                                        : DOMPurify.sanitize(default_bg)
                                    }
                                  // src={dummy_img2}
                                  />
                                  {/* <span onClick={() => onBtnClickOpenLink("")}> */}
                                  <span
                                  // onClick={() =>
                                  //   onClickResource(
                                  //     activeMenu,
                                  //     "history",
                                  //     data.BookId,
                                  //     data.BookName[
                                  //       data.BookName[0].Lang == lang ? 0 : 1
                                  //     ].BookName
                                  //   )
                                  // }
                                  >
                                    {t("Launch")} &nbsp;&nbsp;
                                    <i className="icon mdi mdi-open-in-new new-open-icon"></i>
                                  </span>
                                </a>
                                <label className="book-item-title">
                                  {data.BookName[0].BookName}
                                </label>
                                {role == "T" && !assessfavIds[data.BookId] ? (
                                  <button
                                    className={`buttons mobile-heart-btn heart-btn ${lang == "en-US" ? "" : "heart-btn-chinese"
                                      }`}
                                    onClick={(e) => {
                                      updateAssess(
                                        e,
                                        data.BookId,
                                        "yes",
                                        data.BookName[0].BookName
                                      );
                                      // setAddToFavourite([
                                      //   ...addToFavourite,
                                      //   data.BookId,
                                      // ]);
                                    }}
                                  >
                                    <img
                                      className="like-icon-container"
                                      src={likeIcon}
                                    />
                                  </button>
                                ) : (
                                  role == "T" && (
                                    <button
                                      className="buttons unfavourite-btn mobile-unfavourite-btn"
                                      // onClick={()}
                                      onClick={(e) => {
                                        updateAssess(
                                          e,
                                          data.BookId,
                                          "no",
                                          data.BookName[0].BookName
                                        );
                                        // const removedFav = addToFavourite.filter(
                                        //   (el) => el !== data.id
                                        // );
                                        // setAddToFavourite(removedFav);
                                        // removeFavourite(data.BookId);
                                      }}
                                    >
                                      {t("Unfavourite")}
                                    </button>
                                  )
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="load-spinners">
                          {/* <Loading type={`normal`} /> */}
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {loadData && (
                    <div className="load-spinners">
                      <Loading type={`normal`} />
                    </div>
                  )}
                  {!loadData && bookData.length > 0 && (
                    <FavouritePageStudent
                      addToFavourite={addToFavourite}
                      allData={bookData}
                      studentClassId={studentClassId}
                      loadBookNotification={loadBookNotification}
                      toggleClickable={toggleClickable}
                      loadingLogo={loadingLogo}
                      activeMenu={activeMenu}
                      t={t}
                      role={role}
                      studyGroupList={studyGroupList}
                      onBtnClickOpenLink={onBtnClickOpenLink}
                      onClickTool={onClickTool}
                      onClickResource={onClickResource}
                      notificationPopupOverlay={notificationPopupOverlay}
                      setNotificationPopupOverlay={setNotificationPopupOverlay}
                      activeMenuName={activeMenuName}
                    />
                  )}
                </>
              ))}

            {!loadingLogo &&
              (bookSeriesData.length == 0 || bookData.length == 0) && (
                <label className="default-messages">{t("There is no content")}</label>
              )}
          </div>
          {/* {googleAccessLoading && <Loading />} */}
          <GCModal
            {...props}
            stopLoader={() => {
              // setGoogleAccessLoading(false);
            }}
          />
          <div className="dashboard-footer">
            <Footer t={t} />
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardPage;